import { defineStore } from "pinia";

const useConstant = defineStore({
  id: "constant",
  state: () => ({
    davinciData: [
      {
        name: "Free_Templates",
        code: "Free_Templates",
        title: "Free Templates",
        desc: "",
        imgUrl: "0.jpg",
        mp4Url: "",
        classify: "",
        lable: ["4K", "WIN", "MAC", "FREE"],
      },
      {
        name: "Batch_Copy_Tools",
        code: "Batch_Copy_Tools",
        title: "Batch Copy Tools",
        desc: "",
        imgUrl: "20.jpg",
        mp4Url: "",
        classify: "SCRIPT",
        lable: ["4K","FRAME", "SCREEN", "WIN", "MAC"],
      },
      {
        name: "Subtitle_Tools",
        code: "Subtitle_Tools",
        title: "Subtitle Tools",
        desc: "",
        imgUrl: "19.jpg",
        mp4Url: "",
        classify: "SCRIPT",
        lable: ["4K","FRAME", "SCREEN", "WIN", "MAC"],
      },
      {
        name: "CallOut_Templates",
        code: "CallOut_Templates",
        title: "Call Out V2",
        desc: "Call-out呼出框标题预字幕包，一共包含12套样式，每套分为左右两个。",
        imgUrl: "2.jpg",
        mp4Url: "",
        classify: "TITLES",
        lable: ["4K", "SCREEN", "WIN", "MAC"],
      },
      {
        name: "Slide_Motions_Templates",
        code: "Slide_Motions_Templates",
        title: "Slide Motion Templates",
        desc: "",
        imgUrl: "18.jpg",
        mp4Url: "",
        classify: "EFFECTS",
        lable: ["4K","FRAME", "SCREEN", "WIN", "MAC"],
      },
      {
        name: "Split_Screen_Templates",
        code: "Split_Screen_Templates",
        title: "Split Screen Templates V2",
        desc: "分屏模板是一组包含多个分屏样式的模板合集，目前为V2版本，新增了动态关键帧调节方式，让分屏制作更加简单效率",
        imgUrl: "9.jpg",
        mp4Url: "",
        classify: "EFFECTS",
        lable: ["4K", "FRAME", "SCREEN", "WIN", "MAC"],
      },
      {
        name: "Cinematic_Titles_Template",
        code: "Cinematic_Titles_Template",
        title: "Cinematic Titles Templates",
        desc: "ViccoVlog Cinematic Titles 电影标题模板是一组包含多种类型的逐字动画效果标题模板，它不仅包含标题动画，同时也有组合的数字滚动效果以及滚动字幕，来配合在更多场景下应用",
        imgUrl: "17.jpg",
        mp4Url: "",
        classify: "TITLES",
        lable: ["4K", "FRAME", "SCREEN", "WIN", "MAC"],
      },
      {
        name: "Image_Effect_Templates",
        code: "Image_Effect_Templates",
        title: "Image Effects Templates V2",
        desc: "图像基础动画预设包含了十多个特效模板文件，可以协助你在达芬奇剪辑中快速为素材，合成内容添加基础动画，例如滑动，旋转，缩放，边框，阴影，甚至是制作卡点效果等等",
        imgUrl: "5.jpg",
        mp4Url: "",
        classify: "EFFECTS",
        lable: ["4K", "FRAME", "SCREEN", "WIN", "MAC"],
      },
      {
        name: "Standard_Transitions_V2",
        code: "Standard_Transitions_V2",
        title: "Standard Transitions V2 ",
        desc: "标准转场V1的升级版本该版本最低兼容达芬奇17，包含了变焦，自旋，马赛克，故障，柔化影集等分类40+模板，100多种样式变化，非常容易使用。",
        imgUrl: "10.jpg",
        mp4Url: "",
        classify: "TRANSITIONS",
        lable: ["4K", "FRAME", "SCREEN", "WIN", "MAC"],
      },
      {
        name: "MG_Animation_Templates",
        code: "MG_Animation_Templates",
        title: "MG Animations Templates",
        desc: "MG动画预设包( MG Animations )一共包含包括标尺，箭头，烟花波纹，焦点，框选，键盘等34组Fusion MG动画预设。",
        imgUrl: "7.jpg",
        mp4Url: "",
        classify: "GENERATORS",
        lable: ["4K", "SCREEN", "WIN", "MAC"],
      },
      {
        name: "Creative_Titles_Templates",
        code: "Creative_Titles_Templates",
        title: "Creative Titles Templates",
        desc: "ViccoVlog Creative Titles 创意标题模板是一组包含多个样式类型的综合模板合集，例如时尚Modern，简约Minimal，企业Corporate等等一共30+个模板文件。",
        imgUrl: "14.jpg",
        mp4Url: "",
        classify: "TITLES",
        lable: ["4K", "SCREEN", "WIN", "MAC"],
      },
      {
        name: "Variety_Show_Text_Templates",
        code: "Variety_Show_Text_Templates",
        title: "Variety Show Titles Templates",
        desc: "ViccoVlog Variety Text Templates 花字模板目前包含30+模板文件分为花(图形)和字(图形+文字)两种类型",
        imgUrl: "13.jpg",
        mp4Url: "",
        classify: "TITLES",
        lable: ["4K", "FRAME", "SCREEN", "WIN", "MAC"],
      },
      {
        name: "VChart_Templates",
        code: "VChart_Templates",
        title: "Vchart Animations Templates",
        desc: "图表套件预设一共6种样式22个文件，同时包含了基于达芬奇17新增的前缀图标，在使用时更容易使用和查找",
        imgUrl: "11.jpg",
        mp4Url: "",
        classify: "GENERATORS",
        lable: ["4K", "FRAME", "WIN", "MAC"],
      },
      {
        name: "Wedding_Titles_Template",
        code: "Wedding_Titles_Template",
        title: "Wedding Titles Templates",
        desc: "ViccoVlog Wedding Titles 婚礼标题模板是一组包含多种样式的艺术效果标题类型的模板，配合花纹，图案图形或者是纯文字，来丰富婚礼神圣，优雅，温暖，柔和氛围的营造",
        imgUrl: "16.jpg",
        mp4Url: "",
        classify: "TITLES",
        lable: ["4K", "FRAME", "SCREEN", "WIN", "MAC"],
      },
      {
        name: "AERO_Glass_Transitions",
        code: "AERO_Glass_Transitions",
        title: "AERO Glass Transitions",
        desc: "A.E.R.O玻璃转场套装预设包含4种类型一共22个转场预设文件",
        imgUrl: "1.jpg",
        mp4Url: "",
        classify: "TRANSITIONS",
        lable: ["4K", "FRAME", "WIN", "MAC"],
      },
      {
        name: "Brush_Paint_Titles_Template",
        code: "Brush_Paint_Titles_Template",
        title: "Brush Paint Titles Templates",
        desc: "ViccoVlog Brush Paint Titles 油彩笔刷模板是一组包含多种样式的手绘笔刷类型的标题模板，同时它还有抽帧的效果与高度自由定制化的配置属性，非常适合轻松，乐趣，亲子等等场景下使用。",
        imgUrl: "15.jpg",
        mp4Url: "",
        classify: "TITLES",
        lable: ["4K", "FRAME", "SCREEN", "WIN", "MAC"],
      },
      {
        name: "SocialMedia_Animation_Templates",
        code: "SocialMedia_Animation_Templates",
        title: "Social Media Animations Templates",
        desc: "社交媒体动画模板(Social Media Animation Templates)，是基于达芬奇17制作的原创模板预设，初始一共包含40个模板文件。分为社交媒体模板，点赞分享模板，自定义Iogo模板三类",
        imgUrl: "12.jpg",
        mp4Url: "",
        classify: "TITLES",
        lable: ["4K", "FRAME", "SCREEN", "WIN", "MAC"],
      },
      {
        name: "Innovation_Titles_Templates",
        code: "Innovation_Titles_Templates",
        title: "Innovation Titles Templates",
        desc: "ViccoVlog Titles 标题预设是最早制作的一套标题字幕动画，包含了二+多个动画模板。",
        imgUrl: "6.jpg",
        mp4Url: "",
        classify: "TITLES",
        lable: ["4K", "SCREEN", "WIN", "MAC"],
      },
      {
        name: "Glitch_Lower_Thirds_30FPS",
        code: "Glitch_Lower_Thirds_30FPS",
        title: "Glitch Titles Thirds 30FPS",
        desc: "故障效果标题预字幕包，一共包含3类型15个样式本套装为30顿率版本，适合在24/25/30顿率下使用，效果最佳",
        imgUrl: "3.jpg",
        mp4Url: "",
        classify: "TITLES",
        lable: ["4K", "SCREEN", "WIN", "MAC"],
      },
      {
        name: "Glitch_Lower_Thirds_60FPS",
        code: "Glitch_Lower_Thirds_60FPS",
        title: "Glitch Titles Thirds 60FPS",
        desc: '故障效果标题预字幕包，一共包含3类型15个样式本套装为60帧率版本，适合在50/60顿率下使用，效果最佳，另有30率以及双帧率套装版本。',
        imgUrl: "4.jpg",
        mp4Url: "",
        classify: "TITLES",
        lable: ["4K", "SCREEN", "WIN", "MAC"],
      },
      {
        name: "Rainbow_Transition",
        code: "Rainbow_Transition",
        title: "Rainbow Transitions",
        desc: "彩虹转场 Rainbow Transitions Full Pack，包含十一套完整转场文件",
        imgUrl: "8.jpg",
        mp4Url: "",
        classify: "TRANSITIONS",
        lable: ["4K", "FRAME", "SCREEN", "WIN", "MAC"],
      },
    ],
    i18n: {
      main: {
        classify: "CLASSIFY",
        orderBy: "OrderBy",
        search: "SEARCH",
        all: "All",
        more: "More",
        home: "Home",
        templates: "Templates",
        introduce: "Introduce",
        stop: "Official Store",
        preview: "Preview",
        teaching: "Teaching",
        buy: "Buy",
        download: "Download",
        receive: 'Receive',
        probation: 'DownloadTrialTemplates',
        format: "Template Format",
        resolution: "Resolution Support",
        screen: "Vertical Screen Support",
        frameRate: "Frame Rate Support",
        minimumVersion: "Minimum Software Version",
        system: "System Support",
        ALL: "ALL",
        TITLES: "TITLES",
        TRANSITIONS: "TRANSITIONS",
        GENERATORS: "GENERATORS",
        EFFECTS: "EFFECTS",
        SCRIPT: "SCRIPT",
        new: "Latest",
        BUY_AUTH_COPYRIGHT: "The copyright of all templates on the official ViccoVlog website belongs to ViccoVlog",
        BUY_PRICE_TITLE: "Price",
        BUY_TITLE: "Templates includes",
        BUY_AUTH: "Authorization and responsibilities",
        VISIT_MEDIA: "Visit Our Social Media",
        wSponsor: 'WechatAppreciation',
        bSponsor: 'StationBCharging',
        sponsorDesc: 'The Free Pack Templates is a free product launched by ViccoVlog. It contains 40+ templates. If you can, please buy me a cup of coffee, or click the button to get the download link directly. :P',
        sponsorReceive: 'Rewarded collect collection',
        directReceive: 'Directly claim',
        coffeSponsor: 'Buy me a coffee',
        catalogNew: 'new'
      },
    },
    introduceData: {
      Free_Templates: {
        "preTitle": "Free Templates",
        "title": "",
        "imgUrl": "0.jpg",
        "decs": "This templates comprises over 40 Davinci Resolve standard format template files created based on suggestions from viewers since the launch of ViccoVlog's channel. It covers transitions, titles, generators, and effects, supporting free usage in various personal and commercial videos.",
        "format": "drfx / setting",
        "resolution": "1920x1080, 4K",
        "screen": "No",
        "frameRate": "Any frame rate",
        "minimumVersion": "DaVinci Resolve 17.2.1 (setting) / DaVinci Resolve 17.2.1 (drfx)",
        "system": "Windows10 / Mac / Linux"
      },
      Batch_Copy_Tools: {
        preTitle: "Batch Copy Tools",
        title: "Batch Copy Tools",
        imgUrl: "20.jpg",
        decs: "This is the second scripting tool for ViccoVlog. It is written using the native API and requires only the installation of DaVinci Resolve. It addresses the shortcomings of DaVinci's batch copy feature and extends the functionality for batch copying and pasting attributes in Fusion clips or templates. Simple and easy to use, it is an essential tool for improving work efficiency.",
        format: "Lua",
        resolution: "Any resolution",
        screen: "Lua",
        frameRate: "Any frame rate",
        minimumVersion:
          "DaVinci Resolve 17.2.1",
        system: "Windows10 / Mac / Linux",
      },
      Subtitle_Tools: {
        preTitle: "Subtitle Tools",
        title: "",
        imgUrl: "19.jpg",
        decs: "This is ViccoVlog's first script tool. Written using the native API of Davinci Resolve, it only requires the installation of Davinci Resolve, without the need for other environments. It can generate SRT external subtitles, create Fusion title subtitles with one click based on Fusion title presets, and also supports custom Fusion title templates. It is a practical tool for creating subtitles.",
        format: "Lua",
        resolution: "Any resolution",
        screen: "Yes",
        frameRate: "Any frame rate",
        minimumVersion:
          "DaVinci Resolve 17.2.1",
        system: "Windows10 / Mac / Linux",
      },
      Slide_Motions_Templates: {
        preTitle: "Slide Motion",
        title: "",
        imgUrl: "18.jpg",
        decs: "This is a suite of template packages that includes 50+ sliding multi-screen effects, as well as special effects and transitions. It also includes exclusive vertical screen styles. Each template is carefully crafted to provide you with more creativity and possibilities when editing and transitioning between multiple segments of materials.",
        format: "drfx / setting",
        resolution: "1920x1080，4K",
        screen: "Yes (only supports standard 9:16 vertical screen)",
        frameRate: "Any frame rate",
        minimumVersion:
          "DaVinci Resolve 17.2.1 (setting) / DaVinci Resolve 17.2.1 (drfx)",
        system: "Windows10 / Mac / Linux",
      },
      AERO_Glass_Transitions: {
      "preTitle": "AERO Glass Transition Templates",
      "title": "",
      "imgUrl": "1.jpg",
      "decs": "Specially designed glass transitions tailored for an elegant, high-end glass effect, presenting more dynamic visuals in midnight or translucent scenes. Each direction or shape of the glass transition effect comes with color and Gaussian blur adjustments, enhancing visual harmony.",
      "format": "drfx / setting",
      "resolution": "1920x1080, 4K",
      "screen": "No",
      "frameRate": "Any frame rate",
      "minimumVersion": "DaVinci Resolve 16.2 (setting) / DaVinci Resolve 17.2.1 (drfx)",
      "system": "Windows10 / Mac / Linux"
    },
      Brush_Paint_Titles_Template: {
        "preTitle": "Brush Paint Titles Template",
        "title": "",
        "imgUrl": "15.jpg",
        "decs": "This type of template frequently appears in creatively rich videos because of its unique frame-by-frame display, simulating a frame-by-frame animation effect. It's interesting and is commonly used in video production, especially when paired with such titles.",
        "format": "drfx / setting",
        "resolution": "1920x1080, 4K",
        "screen": "Yes (only supports standard 9:16 vertical screen)",
        "frameRate": "Any frame rate",
        "minimumVersion": "DaVinci Resolve 17.2.1 (setting) / DaVinci Resolve 17.2.1 (drfx)",
        "system": "Windows10 / Mac / Linux"
      },
      CallOut_Templates: {
        "preTitle": "Call Out Templates V2",
        "title": "",
        "imgUrl": "2.jpg",
        "decs": "The all-new V2 version of the call-out box makes it easier to input multi-line titles, and provides more freedom to control the line style, focus style, and direction of the pointer. The animations have also evolved significantly, allowing for seamless adjustment of the speed of entry and exit animations, with the option to easily turn them off. Additionally, nesting multiple call-out boxes within Fusion segments allows for effortless setting of animation offsets with just one click.",
        "format": "drfx / setting",
        "resolution": "1920x1080, 4K",
        "screen": "Yes (only supports standard 9:16 vertical screen)",
        "frameRate": "Any frame rate",
        "minimumVersion": "DaVinci Resolve 17.2.1 (setting) / DaVinci Resolve 17.2.1 (drfx)",
        "system": "Windows10 / Mac / Linux"
      },
      Cinematic_Titles_Template: {
        "preTitle": "Cinematic Titles Templates",
        "title": "",
        "imgUrl": "17.jpg",
        "decs": "A set of minimalist, cinematic titles that innovatively use multiple masks, making it easier in Davinci Resolve to apply different colors to the same text. All templates use character-by-character animation, and each can separately control the entry and exit animation, allowing for more customized effects on the editing page, creating unique title templates.",
        "format": "drfx / setting",
        "resolution": "1920x1080, 4K",
        "screen": "Yes (only supports standard 9:16 vertical screen)",
        "frameRate": "Any frame rate",
        "minimumVersion": "DaVinci Resolve 17.0 (setting) / DaVinci Resolve 17.2.1 (drfx)",
        "system": "Windows10 / Mac / Linux"
      },
      Creative_Titles_Templates: {
        "preTitle": "Creative Titles Templates",
        "title": "",
        "imgUrl": "14.jpg",
        "decs": "This set showcases commercial-style designs and features minimalist character-by-character animation effects that can be applied in various types of videos and films. It's one of Vicco's most frequently used title effects.",
        "format": "drfx / setting",
        "resolution": "1920x1080, 4K",
        "screen": "Yes (only supports standard 9:16 vertical screen)",
        "frameRate": "23 ~ 60 frames",
        "minimumVersion": "DaVinci Resolve 17.0 (setting) / DaVinci Resolve 17.2.1 (drfx)",
        "system": "Windows10 / Mac / Linux"
      },
      Glitch_Lower_Thirds_30FPS: {
        "preTitle": "Glitch Titles Templates 30FPS",
        "title": "",
        "imgUrl": "3.jpg",
        "decs": "This is a cyberpunk-style title template that combines wireframes, flickering effects, and text to create glitch text effects. Useful for creating games or sci-fi films, it can be used centrally as a title or as a corner badge in videos.",
        "format": "drfx / setting",
        "resolution": "1920x1080, 4K",
        "screen": "Yes (only supports standard 9:16 vertical screen)",
        "frameRate": "23 ~ 30 frames",
        "minimumVersion": "DaVinci Resolve 16.2 (setting) / DaVinci Resolve 17.2.1 (drfx)",
        "system": "Windows10 / Mac / Linux"
      },
      Glitch_Lower_Thirds_60FPS: {
        "preTitle": "Glitch Titles Templates 60FPS",
        "title": "",
        "imgUrl": "4.jpg",
        "decs": "This is a cyberpunk-style title template that combines wireframes, flickering effects, and text to create glitch text effects. Useful for creating games or sci-fi films, it can be used centrally as a title or as a corner badge in videos.",
        "format": "drfx / setting",
        "resolution": "1920x1080, 4K",
        "screen": "Yes (only supports standard 9:16 vertical screen)",
        "frameRate": "50 frames per second to 60 frames per second",
        "minimumVersion": "DaVinci Resolve 16.2 (setting) / DaVinci Resolve 17.2.1 (drfx)",
        "system": "Windows10 / Mac / Linux"
      },
      Image_Effect_Templates: {
        "preTitle": "Image Effects Templates V2",
        "title": "",
        "imgUrl": "5.jpg",
        "decs": "This is a set of basic animation extensions tailored specifically for Davinci Resolve, simplifying the creation of effects like zooming, sliding at any angle, shaking, adding various shadows, shape masks, or outlines. It allows easy application of multiple effects in editing, making simple animations more accessible on the editing page.",
        "format": "drfx / setting",
        "resolution": "1920x1080, 4K",
        "screen": "Yes (only supports standard 9:16 vertical screen)",
        "frameRate": "Any frame rate",
        "minimumVersion": "DaVinci Resolve 17.0 (setting) / DaVinci Resolve 17.2.1 (drfx)",
        "system": "Windows10 / Mac / Linux"
      },
      Innovation_Titles_Templates: {
        "preTitle": "Innovation Titles Templates",
        "title": "",
        "imgUrl": "6.jpg",
        "decs": "This is ViccoVlog's first set of Fusion-based title templates, using keyframe animation and masks to create flowing text animation effects using shapes, lines, and text.",
        "format": "drfx / setting",
        "resolution": "1920x1080, 4K",
        "screen": "Yes (only supports standard 9:16 vertical screen)",
        "frameRate": "23 ~ 60 frames",
        "minimumVersion": "DaVinci Resolve 16.2 (setting) / DaVinci Resolve 17.2.1 (drfx)",
        "system": "Windows10 / Mac / Linux"
      },
      MG_Animation_Templates: {
        "preTitle": "MG Animations Templates",
        "title": "",
        "imgUrl": "7.jpg",
        "decs": "This is a comprehensive MG animation suite featuring interesting and commonly used small animations such as shapes, annotations, rulers, flowing backgrounds, keyboard effects, etc. Each one is customizable in terms of position, size, color, etc. by dragging onto the timeline and adjusting parameters.",
        "format": "drfx / setting",
        "resolution": "1920x1080, 4K",
        "screen": "Yes (only supports standard 9:16 vertical screen)",
        "frameRate": "23 ~ 60 frames",
        "minimumVersion": "DaVinci Resolve 16.2 (setting) / DaVinci Resolve 17.2.1 (drfx)",
        "system": "Windows10 / Mac / Linux"
      },
      Rainbow_Transition: {
        "preTitle": "Rainbow Transitions Templates",
        "title": "",
        "imgUrl": "8.jpg",
        "decs": "This is a set of transition templates designed specifically for lively and cheerful themes. It uses various angular or shape masks to simulate a rainbow style. If you need to create transition effects in a cheerful-themed video, this is the perfect choice.",
        "format": "drfx / setting",
        "resolution": "1920x1080, 4K",
        "screen": "Yes (only supports standard 9:16 vertical screen)",
        "frameRate": "Any frame rate",
        "minimumVersion": "DaVinci Resolve 17.0 (setting) / DaVinci Resolve 17.2.1 (drfx)",
        "system": "Windows10 / Mac / Linux"
      },
      SocialMedia_Animation_Templates: {
        "preTitle": "Social Media Animation Templates",
        "title": "",
        "imgUrl": "12.jpg",
        "decs": "If you wish to incorporate personalized social media images into your self-media videos, then this template set is ideal. It integrates various mainstream social media LOGO graphics, each with its own unique animation design, which looks very appealing. We can use it to create follow-subscribe animations or even comment animations, among others. Of course, if you want to use custom LOGO images, this template also provides customizable options.",
        "format": "drfx / setting",
        "resolution": "1920x1080, 4K",
        "screen": "Yes (only supports standard 9:16 vertical screen)",
        "frameRate": "Any frame rate",
        "minimumVersion": "DaVinci Resolve 17.0 (setting) / DaVinci Resolve 17.2.1 (drfx)",
        "system": "Windows10 / Mac / Linux"
      },
      Split_Screen_Templates: {
        "preTitle": "Split Screen Template V2",
        "title": "",
        "imgUrl": "9.jpg",
        "decs": "This is the best tool for creating split-screen effects, utilizing the unique features of Davinci Resolve effects to create a unique template application method, making it easier to apply split screens to multiple materials. This template integrates various commonly used types of split-screen effects. Additionally, it supports unlimited speed animation adjustment and free settings for entry and exit. It also offers free switching between sliding and zooming in entry and exit animations, making it very convenient.",
        "format": "drfx / setting",
        "resolution": "1920x1080, 4K",
        "screen": "Yes (only supports standard 9:16 vertical screen)",
        "frameRate": "Any frame rate",
        "minimumVersion": "DaVinci Resolve 17.0 (setting) / DaVinci Resolve 17.2.1 (drfx)",
        "system": "Windows10 / Mac / Linux"
      },
      Standard_Transitions_V2: {
        "preTitle": "Standard Transitions V2",
        "title": "",
        "imgUrl": "10.jpg",
        "decs": "ViccoVlog currently has the widest application range and the most diverse category of transition packages. It provides almost all commonly used transition effects, making it the most perfect content expansion for DaVinci's official transitions. It's an extremely practical basic transition template, including zoom, slide, glare, mask, and more than a dozen other commonly used transition effects, sufficient to meet the needs for basic transition effects.",
        "format": "drfx / setting",
        "resolution": "1920x1080, 4K",
        "screen": "Yes (only supports standard 9:16 vertical screen)",
        "frameRate": "Any frame rate",
        "minimumVersion": "DaVinci Resolve 17.0 (setting) / DaVinci Resolve 17.2.1 (drfx)",
        "system": "Windows10 / Mac / Linux"
      },
      Variety_Show_Text_Templates: {
        "preTitle": "Variety Show Text Templates",
        "title": "",
        "imgUrl": "13.jpg",
        "decs": "This is a rare original flower-text template in the Davinci Resolve template world. In our usual production of short videos or self-media videos, this type of title text is indispensable. It's a content set with an extremely wide range of applications. The graphics used in the flower text are all from hand-drawn graphics. Vector graphics can ensure super fine display effects at various resolutions. Text and colors can be freely customized. Entry and exit animations also use a smooth speed adjustment method for more refined and free adjustments.",
        "format": "drfx / setting",
        "resolution": "1920x1080, 4K",
        "screen": "Yes (only supports standard 9:16 vertical screen)",
        "frameRate": "Any frame rate",
        "minimumVersion": "DaVinci Resolve 17.0 (setting) / DaVinci Resolve 17.2.1 (drfx)",
        "system": "Windows10 / Mac / Linux"
      },
      VChart_Templates: {
        "preTitle": "VChart Templates",
        "title": "",
        "imgUrl": "11.jpg",
        "decs": "These are exclusive chart-type templates created by ViccoVlog for use in the Davinci Resolve environment. We can easily demonstrate bar chart animations through multiple-track overlays, create line charts, pie charts, and even very beautiful radar charts. Of course, it also includes various progress bars, countdowns, and many other chart effects.",
        "format": "drfx / setting",
        "resolution": "1920x1080, 4K",
        "screen": "No",
        "frameRate": "Any frame rate",
        "minimumVersion": "DaVinci Resolve 17.0 (setting) / DaVinci Resolve 17.2.1 (drfx)",
        "system": "Windows10 / Mac / Linux"
      },
      Wedding_Titles_Template: {
        "preTitle": "Wedding Titles Templates",
        "title": "",
        "imgUrl": "16.jpg",
        "decs": "Elegance, dignity, and simplicity were the original intentions behind the design of this template. It partly adopts a presentation style that combines smooth hand-drawn vector graphics with text and partly uses easing effects for animated text, making it more simple and atmospheric. The complexity of this template lies in the fact that every graphic, even a single leaf, has its own unique animation style. Therefore, the overall animation effect appears more refined and elegant. If you are producing a wedding-themed film, this template will undoubtedly provide a more artistic atmosphere.",
        "format": "drfx / setting",
        "resolution": "1920x1080, 4K",
        "screen": "Yes (only supports standard 9:16 vertical screen)",
        "frameRate": "Any frame rate",
        "minimumVersion": "DaVinci Resolve 17.0 (setting) / DaVinci Resolve 17.2.1 (drfx)",
        "system": "Windows10 / Mac / Linux"
      }
    },
    buyData: {
      Free_Templates: {
        preTitle: "Free Templates",
        title: "",
        imgUrl: "0.jpg",
        tryUrl: '',
        more:   [
          {
            title: "Free Templates",
            imgUrl: "1.jpg",
            buyUrl: "https://drive.google.com/drive/folders/1USg8EtTbiw-DDdBuPjdgh_5h5CyNQ1EI?usp=sharing",
            price: "0",
          },
        ]
      },
      Batch_Copy_Tools: {
        preTitle: "",
        title: "批量复制工具",
        imgUrl: "20.jpg",
        tryUrl:"https://pan.baidu.com/s/1MlGGwIrG-3nds6Q0s5vHNg?pwd=wn4d",
        more:   [
          {
            title: "字幕工具",
            imgUrl: "20.jpg",
            buyUrl: "https://buymeacoffee.com/viccovlog/e/282418",
            price: "19.9",
          },
          // {
          //   title: "ViccoVlog 达芬奇 “我全要” 全18+1套模板合集 V7",
          //   imgUrl: "7.jpg",
          //   buyUrl:
          //     "https://item.taobao.com/item.htm?id=784675556642&spm=a1z10.1-c-s.w4004-23621573054.39.23b813b3ZCTh3y",
          //   price: "316",
          // },
        ]
      },

      Subtitle_Tools: {
        preTitle: "",
        title: "Subtitle Tools",
        imgUrl: "19.jpg",
        tryUrl:"https://pan.baidu.com/s/1THatWKnSGe8k4wJuTOohtw?pwd=gmp7",
        more:   [
          {
            title: "字幕工具",
            imgUrl: "18.jpg",
            buyUrl: "https://buymeacoffee.com/viccovlog/e/255199",
            price: "9",
          },
          // {
          //   title: "ViccoVlog 达芬奇 “我全要” 全18+1套模板合集 V7",
          //   imgUrl: "7.jpg",
          //   buyUrl:
          //     "https://item.taobao.com/item.htm?id=784675556642&spm=a1z10.1-c-s.w4004-23621573054.39.23b813b3ZCTh3y",
          //   price: "316",
          // },
        ]
      },
      Slide_Motions_Templates: {
        preTitle: "",
        title: "Slide Motions Templates",
        imgUrl: "18.jpg",
        // tryUrl:"https://pan.baidu.com/s/1O2vSLyIA0fZvBWHpTaPUhg?pwd=6ii7",
        more:   [
          {
            title: "滑屏动效模板",
            imgUrl: "18.jpg",
            buyUrl: "https://buymeacoffee.com/viccovlog/e/244542",
            price: "19.9",
          },
          {
            title: "ViccoVlog All In One Templates Pack V7 (18 in 1)",
            imgUrl: "7.jpg",
            buyUrl:"https://buymeacoffee.com/viccovlog/e/244543",
            price: "115",
          },
        ]
      },
      AERO_Glass_Transitions: {
        preTitle: "AERO Glass Transitions Templates",
        title: "",
        imgUrl: "1.jpg",
        more: [
          {
            title: "ViccoVlog Aero Glass Transitions",
            imgUrl: "1.jpg",
            buyUrl:
              "https://www.buymeacoffee.com/viccovlog/e/24276",
            price: "5",
          },
          {
            title: "ViccoVlog All In One Templates Pack V7 (18 in 1)",
            imgUrl: "7.jpg",
            buyUrl:"https://buymeacoffee.com/viccovlog/e/244543",
            price: "115",
          },
          {
            title: "ViccoVlog Transitions Pack ( 3 in 1 )",
            imgUrl: "4.jpg",
            buyUrl:
              "https://www.buymeacoffee.com/viccovlog/e/190417",
            price: "15",
          },
        ]
      },
      Brush_Paint_Titles_Template: {
        preTitle: "Brush Paint Titles Templates",
        title: "",
        imgUrl: "15.jpg",
        more: [
          {
            title: "ViccoVlog 达芬奇原创 油彩笔刷标题模板 Brush Paint Titles",
            imgUrl: "2.jpg",
            buyUrl:
              "https://www.buymeacoffee.com/viccovlog/e/85086",
            price: "10",
          },
          {
            title: "ViccoVlog All In One Templates Pack V7 (18 in 1)",
            imgUrl: "7.jpg",
            buyUrl:"https://buymeacoffee.com/viccovlog/e/244543",
            price: "115",
          },
          {
            title: "ViccoVlog Titles Pack V1 ( 9 in 1 ）",
            imgUrl: "5.jpg",
            buyUrl:
              "https://www.buymeacoffee.com/viccovlog/e/190418",
            price: "65",
          },
        ]
      } ,
      CallOut_Templates: {
        preTitle: "Call Out Templates V2",
        title: "",
        imgUrl: "2.jpg",
        more: [
          {
            title: "ViccoVlog 达芬奇 Call-out呼出框标题字幕预设包",
            imgUrl: "2.jpg",
            buyUrl:"https://buymeacoffee.com/viccovlog/e/24267",
            price: "10",
          },
          {
            title: "ViccoVlog All In One Templates Pack V7 (18 in 1)",
            imgUrl: "7.jpg",
            buyUrl:"https://buymeacoffee.com/viccovlog/e/244543",
            price: "115",
          },
          {
            title: "ViccoVlog Titles Pack V1 ( 9 in 1 ）",
            imgUrl: "5.jpg",
            buyUrl:
              "https://www.buymeacoffee.com/viccovlog/e/190418",
            price: "65",
          },
        ]
      },
      Cinematic_Titles_Template: {
        preTitle: "Cinematic Titles Template",
        title: "",
        imgUrl: "17.jpg",
        more: [
          {
            title: "ViccoVlog 达芬奇原创 电影标题模板 Cinematic Titles",
            imgUrl: "2.jpg",
            buyUrl:
              "https://www.buymeacoffee.com/viccovlog/e/145743",
            price: "15",
          },
          {
            title: "ViccoVlog All In One Templates Pack V7 (18 in 1)",
            imgUrl: "2.jpg",
            buyUrl:"https://buymeacoffee.com/viccovlog/e/244543",
            price: "115",
          },
          {
            title: "ViccoVlog Titles Pack V1 ( 9 in 1 ）",
            imgUrl: "5.jpg",
            buyUrl:
              "https://www.buymeacoffee.com/viccovlog/e/190418",
            price: "65",
          },
        ]
      },
      Creative_Titles_Templates: {
        preTitle: "Creative Titles Templates",
        title: "",
        imgUrl: "14.jpg",
        more: [
          {
            title: "ViccoVlog Creative Titles",
            imgUrl: "2.jpg",
            buyUrl:
              "https://www.buymeacoffee.com/viccovlog/e/55802",
            price: "10",
          },
          {
            title: "ViccoVlog All In One Templates Pack V7 (18 in 1)",
            imgUrl: "7.jpg",
            buyUrl:"https://buymeacoffee.com/viccovlog/e/244543",
            price: "115",
          },
          {
            title: "ViccoVlog Titles Pack V1 ( 9 in 1 ）",
            imgUrl: "5.jpg",
            buyUrl:
              "https://www.buymeacoffee.com/viccovlog/e/190418",
            price: "65",
          },
        ]
      },
      Glitch_Lower_Thirds_30FPS: {
        preTitle: "Glitch Titles Templates 30FPS",
        title: "",
        imgUrl: "3.jpg",
        more: [
          {
            title: "ViccoVlog Glitch Titles 30FPS",
            imgUrl: "2.jpg",
            buyUrl:
              "https://www.buymeacoffee.com/viccovlog/e/24270",
            price: "5",
          },
          {
            title: "ViccoVlog All In One Templates Pack V7 (18 in 1)",
            imgUrl: "7.jpg",
            buyUrl:"https://buymeacoffee.com/viccovlog/e/244543",
            price: "115",
          },
          {
            title: "ViccoVlog Titles Pack V1 ( 9 in 1 ）",
            imgUrl: "5.jpg",
            buyUrl:
              "https://www.buymeacoffee.com/viccovlog/e/190418",
            price: "65",
          },
          {
            title: "ViccoVlog Glitch Title 30&60FPS",
            imgUrl: "3.jpg",
            buyUrl:
              "https://www.buymeacoffee.com/viccovlog/e/24275",
            price: "8",
          },
        ]
      },
      Glitch_Lower_Thirds_60FPS: {
        preTitle: "Glitch Titles Templates 60FPS",
        title: "",
        imgUrl: "4.jpg",
        more:  [
          {
            title: "ViccoVlog 达芬奇 Glitch故障效果标题字幕预设包 60帧率版本",
            imgUrl: "2.jpg",
            buyUrl:
              "https://www.buymeacoffee.com/viccovlog/e/24273",
            price: "5",
          },
          {
            title: "ViccoVlog All In One Templates Pack V7 (18 in 1)",
            imgUrl: "7.jpg",
            buyUrl:"https://buymeacoffee.com/viccovlog/e/244543",
            price: "115",
          },
          {
            title: "ViccoVlog Titles Pack V1 ( 9 in 1 ）",
            imgUrl: "5.jpg",
            buyUrl:
              "https://www.buymeacoffee.com/viccovlog/e/190418",
            price: "65",
          },
          {
            title: "ViccoVlog Glitch Title 30&60FPS",
            imgUrl: "3.jpg",
            buyUrl:
              "https://www.buymeacoffee.com/viccovlog/e/24275",
            price: "8",
          },
        ]
      },
      Image_Effect_Templates: {
        preTitle: "Image Effects Templates V2",
        title: "",
        imgUrl: "5.jpg",
        more:  [
          {
            title: "ViccoVlog 达芬奇 图像基础动画 V2 Image Effects 预设模板套件",
            imgUrl: "2.jpg",
            buyUrl:
              "https://www.buymeacoffee.com/viccovlog/e/24277",
            price: "10",
          },
          {
            title: "ViccoVlog All In One Templates Pack V7 (18 in 1)",
            imgUrl: "7.jpg",
            buyUrl:"https://buymeacoffee.com/viccovlog/e/244543",
            price: "115",
          },
          {
            title: "ViccoVlog Newbie Pack ( 4 in 1)",
            imgUrl: "1.jpg",
            buyUrl:
              "https://www.buymeacoffee.com/viccovlog/e/190415",
            price: "35",
          },
        ]
      },
      Innovation_Titles_Templates: {
        preTitle: "Innovation Titles Templates",
        title: "",
        imgUrl: "6.jpg",
        more: [
          {
            title: "ViccoVlog 达芬奇原创 标题动画模板 Innovation Titles",
            imgUrl: "2.jpg",
            buyUrl:
              "https://www.buymeacoffee.com/viccovlog/e/38444",
            price: "5",
          },
          {
            title: "ViccoVlog All In One Templates Pack V7 (18 in 1)",
            imgUrl: "7.jpg",
            buyUrl:"https://buymeacoffee.com/viccovlog/e/244543",
            price: "115",
          },
          {
            title: "ViccoVlog Titles Pack V1 ( 9 in 1 ）",
            imgUrl: "5.jpg",
            buyUrl:
              "https://www.buymeacoffee.com/viccovlog/e/190418",
            price: "65",
          },
        ]
      },
      MG_Animation_Templates: {
        preTitle: "MG Animations Templates",
        title: "",
        imgUrl: "7.jpg",
        more: [
          {
            title: "ViccoVlog 达芬奇 MG动画预设模板套件",
            imgUrl: "2.jpg",
            buyUrl:
              "https://www.buymeacoffee.com/viccovlog/e/24256",
            price: "10",
          },
          {
            title: "ViccoVlog All In One Templates Pack V7 (18 in 1)",
            imgUrl: "7.jpg",
            buyUrl:"https://buymeacoffee.com/viccovlog/e/244543",
            price: "115",
          },
          {
            title: "ViccoVlog Newbie Pack ( 4 in 1)",
            imgUrl: "1.jpg",
            buyUrl:
              "https://www.buymeacoffee.com/viccovlog/e/190415",
            price: "35",
          },
        ]
      } ,
      Rainbow_Transition: {
        preTitle: "Rainbow Transitions Templates",
        title: "",
        imgUrl: "8.jpg",
        more: [
          {
            title: "ViccoVlog 达芬奇原创彩虹转场Rainbow Transitions 预设模板套件",
            imgUrl: "2.jpg",
            buyUrl:
              "https://www.buymeacoffee.com/viccovlog/e/26078",
            price: "5",
          },
          {
            title: "ViccoVlog All In One Templates Pack V7 (18 in 1)",
            imgUrl: "7.jpg",
            buyUrl:"https://buymeacoffee.com/viccovlog/e/244543",
            price: "115",
          },
          {
            title: "ViccoVlog Transitions Pack ( 3 in 1 )",
            imgUrl: "4.jpg",
            buyUrl:
              "https://www.buymeacoffee.com/viccovlog/e/190417",
            price: "15",
          },
        ]
      } ,
      SocialMedia_Animation_Templates: {
        preTitle: "Social Media Animations",
        title: "社交媒体模板",
        imgUrl: "12.jpg",
        more: [
          {
            title: "ViccoVlog Social Media Animation Templates",
            imgUrl: "2.jpg",
            buyUrl:
              "https://www.buymeacoffee.com/viccovlog/e/37478",
            price: "10",
          },
          {
            title: "ViccoVlog All In One Templates Pack V7 (18 in 1)",
            imgUrl: "7.jpg",
            buyUrl:"https://buymeacoffee.com/viccovlog/e/244543",
            price: "115",
          },
          {
            title: "ViccoVlog Titles Pack V1 ( 9 in 1 ）",
            imgUrl: "5.jpg",
            buyUrl:
              "https://www.buymeacoffee.com/viccovlog/e/190418",
            price: "65",
          },
        ]
      } ,
      Split_Screen_Templates: {
        preTitle: "Split Screen Templates",
        title: "",
        imgUrl: "9.jpg",
        more: [
          {
            title: "ViccoVlog 达芬奇原创分屏模板Split Screen V2 模板套件",
            imgUrl: "2.jpg",
            buyUrl:
              "https://www.buymeacoffee.com/viccovlog/e/28459",
            price: "10",
          },
          {
            title: "ViccoVlog All In One Templates Pack V7 (18 in 1)",
            imgUrl: "7.jpg",
            buyUrl:"https://buymeacoffee.com/viccovlog/e/244543",
            price: "115",
          },
          {
            title: "ViccoVlog Newbie Pack ( 4 in 1)",
            imgUrl: "1.jpg",
            buyUrl:
              "https://www.buymeacoffee.com/viccovlog/e/190415",
            price: "35",
          },
        ]
      } ,
      Standard_Transitions_V2: {
        preTitle: "Standard Transitions V2",
        title: "",
        imgUrl: "10.jpg",
        more: [
          {
            title: "ViccoVlog 达芬奇原创 标准转场V2 升级版",
            imgUrl: "2.jpg",
            buyUrl:
              "https://www.buymeacoffee.com/viccovlog/e/39201",
            price: "10",
          },
          {
            title: "ViccoVlog All In One Templates Pack V7 (18 in 1)",
            imgUrl: "7.jpg",
            buyUrl:"https://buymeacoffee.com/viccovlog/e/244543",
            price: "115",
          },
          {
            title: "ViccoVlog Transitions Pack ( 3 in 1 )",
            imgUrl: "4.jpg",
            buyUrl:
              "https://www.buymeacoffee.com/viccovlog/e/190417",
            price: "15",
          },
          {
            title: "ViccoVlog Newbie Pack ( 4 in 1)",
            imgUrl: "1.jpg",
            buyUrl:
              "https://www.buymeacoffee.com/viccovlog/e/190415",
            price: "35",
          },
        ]
      } ,
      Variety_Show_Text_Templates: {
        preTitle: "Variety Show Text Templates",
        title: "",
        imgUrl: "13.jpg",
        more: [
          {
            title: "ViccoVlog 达芬奇原创 综艺花字模板 Variety Text Templates",
            imgUrl: "2.jpg",
            buyUrl:
              "https://www.buymeacoffee.com/viccovlog/e/42997",
            price: "12",
          },
          {
            title: "ViccoVlog All In One Templates Pack V7 (18 in 1)",
            imgUrl: "7.jpg",
            buyUrl:"https://buymeacoffee.com/viccovlog/e/244543",
            price: "115",
          },
          {
            title: "ViccoVlog Titles Pack V1 ( 9 in 1 ）",
            imgUrl: "5.jpg",
            buyUrl:
              "https://www.buymeacoffee.com/viccovlog/e/190418",
            price: "65",
          },
        ]
      },
      VChart_Templates: {
        preTitle: "Vchart Templates",
        title: "",
        imgUrl: "11.jpg",
        more: [
          {
            title: "ViccoVlog 达芬奇原创 VChart Templates 图表套件动态模板",
            imgUrl: "2.jpg",
            buyUrl:
              "https://www.buymeacoffee.com/viccovlog/e/33724",
            price: "15",
          },
          {
            title: "ViccoVlog All In One Templates Pack V7 (18 in 1)",
            imgUrl: "7.jpg",
            buyUrl:"https://buymeacoffee.com/viccovlog/e/244543",
            price: "115",
          },
        ]
      },
      Wedding_Titles_Template: {
        preTitle: "Wedding Titles Templates",
        title: "",
        imgUrl: "16.jpg",
        more: [
          {
            title: "ViccoVlog 达芬奇原创 婚礼标题模板 Wedding Titles",
            imgUrl: "2.jpg",
            buyUrl:
              "https://www.buymeacoffee.com/viccovlog/e/99465",
            price: "10",
          },
          {
            title: "ViccoVlog All In One Templates Pack V7 (18 in 1)",
            imgUrl: "7.jpg",
            buyUrl:"https://buymeacoffee.com/viccovlog/e/244543",
            price: "115",
          },
          {
            title: "ViccoVlog Titles Pack V1 ( 9 in 1 ）",
            imgUrl: "5.jpg",
            buyUrl:
              "https://www.buymeacoffee.com/viccovlog/e/190418",
            price: "65",
          },
        ]
      }
    },
    videoData: {
      Free_Templates: {
        title: "Free Templates",
        videoUrl:
          "https://www.youtube.com/embed/e3MrJ7kdNGY?si=1EEiuUSBfbyLltJg",
        more: [

          {
            title: "[Vicco] DaVinci Resolve Drawing Tool | 2024 New Year's First Release | Free Download",
            videoUrl:
              "https://youtu.be/XOJiNsvBIqE?si=stMwTFKujnjpHUDr",
            imgUrl: "0-19.png",
          },

          {

            "title": "[Vicco] Annual Free Template Collection | Davinci Resolve Preset Template Sharing",
            "videoUrl": "https://youtu.be/8NkrNdQzkOY?si=fnr59rQIlRyGVJvn",
            "imgUrl": "0-1.png"
          },
          {
            "title": "[Vicco] Davinci Resolve Original Danmu Generator Template | Double 11 Freebies",
            "videoUrl": "https://youtu.be/MgVLNFgprgI?si=DxAqntr_fkRMHGzQ",
            "imgUrl": "0-2.png"
          },
          {
            "title": "[Vicco] Quickly Create Embossed Text in Davinci Resolve Fusion | Free Template Update",
            "videoUrl": "https://youtu.be/bu52ohvGReM?si=_XgZ2H1QpaHWT6H5",
            "imgUrl": "0-3.png"
          },
          {
            "title": "[Vicco] A super transition V2 that punches ten | Any combination of four transitions",
            "videoUrl": "https://youtu.be/XjwqK7iGpR8?si=ovyfxDyD8zIW4e0O",
            "imgUrl": "0-4.png"
          },
          {
            "title": "[Vicco] Davinci Resolve Typewriter Effect Template V2 | New Template New Usage",
            "videoUrl": "https://youtu.be/dFF1TmYFhFA?si=Uq9fy6YIGBdUE-uX",
            "imgUrl": "0-5.png"
          },
          {
            "title": "[Vicco] Davinci Resolve Homemade Rolling Subtitle Template | Fan Benefits",
            "videoUrl": "https://youtu.be/XbzqBAh0T7g?si=X5cuq2c32HDZgw4O",
            "imgUrl": "0-6.png"
          },
          {
            "title": "[Vicco] Davinci Resolve Homemade Photo Frame Template | Photo Production Efficiency Tool",
            "videoUrl": "https://youtu.be/197Y6fwdCRw?si=IKuUDMLyAl_3URuO",
            "imgUrl": "0-7.png"
          },
          {
            "title": "[Vicco] Davinci Resolve Homemade Frosted Glass Effect Template | Double the Fun for White Time",
            "videoUrl": "https://youtu.be/zQ7Z22xNda4?si=Grx4qhdpE9E0kuob",
            "imgUrl": "0-8.png"
          },
          {
            "title": "[Vicco] Many more useful templates are updated ~ | Image Basics & MG Animation",
            "videoUrl": "https://youtu.be/3iiIQg29IuI?si=b4H5aJMYqWJxTPj4",
            "imgUrl": "0-9.png"
          },
          {
            "title": "[Vicco] Make an interesting magnifying glass in Davinci Resolve | Node Sharing",
            "videoUrl": "https://youtu.be/HS9VJy-69aA?si=uxDRpcFtXEPgcraG",
            "imgUrl": "0-10.png"
          },
          {
            "title": "[Vicco] Highlight text in Davinci Resolve like AE | Free Preset Download",
            "videoUrl": "https://youtu.be/LC1SianOV0E?si=FUrbVm3m6lLtoiPW",
            "imgUrl": "0-11.png"
          },
          {
            "title": "[Vicco] Make a counter in thirty seconds with Davinci Resolve | Preset Download | Davinci Resolve Three Minutes",
            "videoUrl": "https://youtu.be/dVFWSACjfFs?si=9Vl4Fn1ZcnmMq7lM",
            "imgUrl": "0-12.png"
          },
          {
            "title": "[Vicco] A super transition 2.0 version is more powerful, one for ten!",
            "videoUrl": "https://youtu.be/_LvrXEVsczo?si=nPQcYEJjKTI3dYMH",
            "imgUrl": "0-13.png"
          },
          {
            "title": "[Vicco] Create super cool and dynamic speed lines in Davinci Resolve like comics | Node Preset Sharing",
            "videoUrl": "https://youtu.be/n9Oe90YUfoI?si=pVjyyoqNl4XIdHhc",
            "imgUrl": "0-14.png"
          },
          {
            "title": "[Vicco] Davinci Resolve Homemade Effect Preset Skeleton Screen Triple Animation | White Download is here",
            "videoUrl": "https://youtu.be/FJnJZevfRXM?si=7uZEJdLnKPQ5O0u2",
            "imgUrl": "0-15.png"
          },
          {
            "title": "[Vicco] Like AE in Davinci Resolve, play step-by-step with frame extraction | Free Frame Extraction Plugin Double Festival Freebie",
            "videoUrl": "https://youtu.be/V92FwPdPDN8?si=99-w3fuiW9t_IbQB",
            "imgUrl": "0-16.png"
          },
          {
            "title": "[Vicco] Play the snapshot effect in Davinci Resolve | Davinci Resolve Three Minutes | Preset Download",
            "videoUrl": "https://youtu.be/1PEmmgI2kLE?si=Xtw32uYpKyvICk96",
            "imgUrl": "0-17.png"
          },
          {
            "title": "[Vicco] Recreate Peter McKinnon's Title Subtitle in Davinci Resolve | Free Download",
            "videoUrl": "https://youtu.be/B0E1wbTMFLM?si=YruIAD0SDrRRYhtt",
            "imgUrl": "0-18.png"
          }
        ],
      },
      Batch_Copy_Tools: {
        title: "ViccoVlog Batch Copy Tools",
        videoUrl:
          "https://www.youtube.com/embed/WaZNTBKjW-Q?si=lmw4uR5h2k5f5aDi",
        // more: [
        //   {
        //     title: "[大仙] ViccoVlog原创达芬奇字幕转工具",
        //     videoUrl:
        //       "https://www.bilibili.com/video/BV14H4y1G7Ao/?share_source=copy_web&vd_source=b3d7e1a2028c00adb444f2a89fe6cc38",
        //     imgUrl: "9-1.png",
        //   },
        // ]
      },
      Subtitle_Tools: {
        title: "Subtitle Tools",
        videoUrl:
          "https://www.youtube.com/embed/D-wYMomkQCU?si=w3OhngEHiFrXr_Kf",
      },
      Slide_Motions_Templates: {
        title: "Slide Motion Templates",
        videoUrl:
          "https://www.youtube.com/embed/G_nFb2bgDsE?si=n8SZ8Yg_7L8l3hLW",
      },
      AERO_Glass_Transitions: {
        title: "AERO Glass Transitions",
        videoUrl:
          "https://www.youtube.com/embed/a5X33blAER4?si=h55nktDwt0J15RJU",
      },
      Brush_Paint_Titles_Template: {
        title: "Brush Paint Titles Template",
        videoUrl:
          "https://www.youtube.com/embed/Rh44A9KPc4o?si=UMqPIQHdQM8-uGZ0",
      },
      CallOut_Templates: {
        title: "CallOut Templates V2",
        videoUrl:
          "https://www.youtube.com/embed/SJk5PeOUMKM?si=QZI8rcmqUvVhzbnr",
        more: [
          {
            title: "[ViccoVlog] Call-out V1",
            videoUrl:
              "https://youtu.be/C4TRlDWIQKQ?si=iukRCwFMeUp2TgbO",
            imgUrl: "8-1.png",
          },],
      },
      Cinematic_Titles_Template: {
        title: "Cinematic Titles Template",
        videoUrl:
          "https://www.youtube.com/embed/9k5XHExbPdY?si=uuylelX-faZvMgA8",
      },
      Creative_Titles_Templates: {
        title: "Creative Titles Templates",
        videoUrl:
          "https://www.youtube.com/embed/de4Qce9a-Iw?si=aQVsWENX_hEUGwGN",
      },
      Glitch_Lower_Thirds_30FPS: {
        title: "Glitch Lower Thirds 30FPS",
        videoUrl:
          "https://www.youtube.com/embed/8peo0ZUUXrA?si=3Lpsyd0-KLXp1Hmm",
      },
      Glitch_Lower_Thirds_60FPS: {
        title: "Glitch Titles Thirds 60FPS",
        videoUrl:
          "https://www.youtube.com/embed/8peo0ZUUXrA?si=3Lpsyd0-KLXp1Hmm",
      },
      Image_Effect_Templates: {
        title: "Image Effects Templates V2",
        videoUrl:
          "https://www.youtube.com/embed/n0UiFdbc-RE?si=aRNSxQqhY_AfU8ee",
        more: [
          {
            title: "Davinci Resolve Image Basic Animation Template V2 Major Update, Quickly Achieve Keyframe Animation",
            videoUrl: "https://youtu.be/C8-esB736TY?si=Yci9Ptaos3zv5Tpw",
            imgUrl: "1-1.png",
          },
          {
            title: "Create 3D Lens Effects in Davinci Resolve in Ten Seconds, Image Basic Animation Template Update",
            videoUrl: "https://youtu.be/n02AQV52noM?si=cjp7aPoViUmOxz3n",
            imgUrl: "1-2.png",
          },
          {
            title: "Create Custom Masks in One Click on the Davinci Resolve Editing Page, Image Basic Animation Template Update",
            videoUrl: "https://youtu.be/1KZDhzeFZ7A?si=hvlqzR5iOv0J5Nqu",
            imgUrl: "1-3.png",
          },
          {
            title: "Brand New Animation Interaction, Image Basic Animation V2.1 Update",
            videoUrl: "https://youtu.be/F3Y1MQu36gA?si=A_BDkC1v8QMc11r0",
            imgUrl: "1-4.png",
          },
          {
            title: "Davinci Resolve Homemade Sliding Path Template, No Need to Draw Paths in Fusion Anymore",
            videoUrl: "https://youtu.be/TEQYlCW-bik?si=du-X7klHHhA3mffn",
            imgUrl: "1-5.png",
          },
        ],
      },
      Innovation_Titles_Templates: {
        title: "INNOVATION TITLES Templates",
        videoUrl:
          "https://www.youtube.com/embed/dooLWVjmEzM?si=C6oDdzRXMnNIMBdu",
      },
      MG_Animation_Templates: {
        title: "MG Animations Templates",
        videoUrl:
          "https://www.youtube.com/embed/3wPmctvKQ9A?si=tVxITxh-Mc_7dBhR",
        more: [
          {
            title: "Davinci Resolve Homemade MG Animation Kit Template Major Update",
            videoUrl: "https://youtu.be/ECtkZqQ7hZA?si=5aV12hpqituuWH8h",
            imgUrl: "5-1.png",
          },
          {
            title: "Creating Ruler Animation Using Tracking Nodes in Davinci Resolve",
            videoUrl: "https://youtu.be/uBE06lE6Zj0?si=_j9xdWUOvfCZ4g2G",
            imgUrl: "5-2.png",
          },
          {
            title: "Useful and Not Too Many Updates for Templates, Image Basics & MG Animation",
            videoUrl: "https://youtu.be/3iiIQg29IuI?si=DbV5CQUqAq5FOicC",
            imgUrl: "5-4.png",
          },
        ],
      },
      Rainbow_Transition: {
        title: "Rainbow Transitions",
        videoUrl:
          "https://www.youtube.com/embed/2AfeM5TGupo?si=U3c1XvFYIfn77gjh",
      },
      SocialMedia_Animation_Templates: {
        title: "Social Media Animations Templates",
        videoUrl:
          "https://www.youtube.com/embed/ChL6m17DQCI?si=rZvhUUshULHrxvoK",
      },
      Split_Screen_Templates: {
        title: "Split Screen Templates V2",
        videoUrl:
          "https://www.youtube.com/embed/chlWQ8fr2G0?si=4g7gQLNa87A-rymZ",
        more: [
          {
            title: "[Vicco] Dynamic Split Screen In Davinci Resolve 18 | Split Screen Update",
            videoUrl: "https://youtu.be/NRHCIE4Spuw?si=lk63Td-TdyKxDPx6",
            imgUrl: "3-5.png",
          },
          {
            "title": "Davinci Resolve Original Split Screen Template V2, Adaptive Keyframes",
            "videoUrl": "https://youtu.be/bJn_BRZXyuI?si=xPwTzceaRA0tSe02",
            "imgUrl": "3-1.png"
          },
          {
            "title": "Davinci Resolve Original Split Screen Template V2 Update, Free Adjustment & Animation Update",
            "videoUrl": "https://youtu.be/e_cWHGRqAyg?si=LGfe8RuwYsvckQLS",
            "imgUrl": "3-2.png"
          },
          {
            "title": "Davinci Resolve Homemade Split Screen Template Updates Again, Slightly Different",
            "videoUrl": "https://youtu.be/IJOkpS-jQjk?si=ZykB2EIFPBOgrqvQ",
            "imgUrl": "3-3.png"
          },
          {
            "title": "Useful and Not Too Many Updates for Templates, Split Screen & Cutting Tools & Drawing Tools",
            "videoUrl": "https://youtu.be/SFIPnm9GjX0?si=3a9y9IXUxu2WsR7v",
            "imgUrl": "3-4.png"
          }
        ],
      },
      Standard_Transitions_V2: {
        title: "Standard Transitions V2",
        videoUrl:
          "https://www.youtube.com/embed/JHg2YrNIuHI?si=YoNjd18qbXoPfxGt",
        more: [
          {
            "title": "Davinci Resolve Homemade Free Transition Preset Pack 2.0, Official Transition-Like User Experience",
            "videoUrl": "https://youtu.be/_FKjaTgeeVg?si=kdYB99G4ECKKL2Hj",
            "imgUrl": "2-1.png"
          },
          {
            "title": "Davinci Resolve Standard Transitions V2 Update in Progress, 2 New Types, 11 New Templates Added",
            "videoUrl": "https://youtu.be/m2qtuMi9uw4?si=7ncF_O664Q3geiOX",
            "imgUrl": "2-2.png"
          },
          {
            "title": "Davinci Resolve Homemade Flare Transition Templates, Standard Transitions Update",
            "videoUrl": "https://youtu.be/6Hldxb3_-bw?si=hv6EtsFIkMnJnJge",
            "imgUrl": "2-3.png"
          },
          {
            "title": "How to Create Page Flip Transitions in Davinci Resolve, Standard Transitions Update",
            "videoUrl": "https://youtu.be/SMwKTApDgDg?si=av_4jMKG24iKKeeG",
            "imgUrl": "2-4.png"
          },
          {
            "title": "Davinci Resolve Pixel Grid Transitions&Focus Transitions Update, 10 New Templates",
            "videoUrl": "https://youtu.be/c3K0CRTstIc?si=JsCbUcJjn5qTCItS",
            "imgUrl": "2-5.png"
          }
        ],
      },
      Variety_Show_Text_Templates: {
        title: "Variety Show Titles Templates",
        videoUrl:
          "https://www.youtube.com/embed/Hd9aQR6niz4?si=JP8BdlfM0pHkXZwN",
        more: [
          {
            "title": "Davinci Resolve Original Variety Show Flower Text Template Major Update, Collection of 52 Templates",
            "videoUrl": "https://youtu.be/P1AN8zTD8EA?si=_bRPDCUT7hZZwdnf",
            "imgUrl": "4-1.png"
          },
          {
            "title": "October Templates Updated Again, Flower Text Templates & Free Collection Update",
            "videoUrl": "https://youtu.be/gYYOXf7oP10?si=ng0iY29jlIrl5_Eu",
            "imgUrl": "4-2.png"
          }
        ],
      },
      VChart_Templates: {
        title: "Vchart Animations Templates",
        videoUrl:
          "https://www.youtube.com/embed/obXiyitoK9U?si=OgDdzxMutvd5Weij",
        more: [
          {
            "title": "[Vicco] Chart Suite Update | Rolling Numbers | Timer | Update of 11 Templates",
            "videoUrl": "https://youtu.be/vagWTpcHT9A?si=Ov9mzoa8mXY-48U9",
            "imgUrl": "6-1.png"
          },
          {
            "title": "[Vicco] Regular Update of Chart Templates | Five Templates",
            "videoUrl": "https://youtu.be/XUYZ-ax94D0?si=5_VWQ5iMbzC9DYGw",
            "imgUrl": "6-2.png"
          },
          {
            "title": "[Vicco] Regular Update in July | Split Screen | Chart | Media Animation | Vertical Text",
            "videoUrl": "https://youtu.be/AFgmd2ikBS0?si=btG53PSnOyCWEiws",
            "imgUrl": "6-3.png"
          },
          {
            "title": "[Vicco] Davinci Resolve Homemade Flip Number Template | Free Update of Chart Templates",
            "videoUrl": "https://youtu.be/MLpCu0hbEHA?si=JQAPHl5HyH2AE1hY",
            "imgUrl": "6-4.png"
          },
        ],
      },
      Wedding_Titles_Template: {
        title: "Wedding Titles Template",
        videoUrl:
          "https://www.youtube.com/embed/CnBd-_pnHf8?si=53SP_njPx8t9_kQI",
      },
    },
    effectData: {
      Free_Templates: [
        {
          name: "dan mu",
          videoName: "生成器/V1-0021_21.mp4",
          classify: "Generator",
          newTemplate: true
        },
        {
          name: "line",
          videoName: "生成器/V1-0022_22.mp4",
          classify: "Generator",
          newTemplate: true
        },
        {
          "name": "Ez_Glitch",
          "videoName": "特效/V1-0001_1.mp4",
          "classify": "Effects"
        },
        {
          "name": "Shape_Frosted Glass",
          "videoName": "特效/V1-0002_2.mp4",
          "classify": "Effects"
        },
        {
          "name": "Shape_Rectangle",
          "videoName": "特效/V1-0003_3.mp4",
          "classify": "Effects"
        },
        {
          "name": "Frame Extraction Tool",
          "videoName": "特效/V1-0004_4.mp4",
          "classify": "Effects"
        },
        {
          "name": "Magnifying Glass_Circular",
          "videoName": "特效/V1-0005_5.mp4",
          "classify": "Effects"
        },
        {
          "name": "Magnifying Glass_Rectangle",
          "videoName": "特效/V1-0006_6.mp4",
          "classify": "Effects"
        },
        {
          "name": "Photo Frame",
          "videoName": "特效/V1-0007_7.mp4",
          "classify": "Effects"
        },
        {
          "name": "Matte Glass_Text",
          "videoName": "特效/V1-0008_8.mp4",
          "classify": "Effects"
        },
        {
          "name": "Golden Text_Texture",
          "videoName": "特效/V1-0009_9.mp4",
          "classify": "Effects"
        },
        {
          "name": "Skeleton Screen_Bilibili",
          "videoName": "特效/V1-0010_10.mp4",
          "classify": "Effects"
        },
        {
          "name": "Skeleton Screen_Youtube",
          "videoName": "特效/V1-0011_11.mp4",
          "classify": "Effects"
        },
        {
          "name": "Photography_iPhone_Horizontal_01",
          "videoName": "生成器/V1-0001_1.mp4",
          "classify": "Generator"
        },
        {
          "name": "Photography_iPhone_Horizontal_02",
          "videoName": "生成器/V1-0002_2.mp4",
          "classify": "Generator"
        },
        {
          "name": "Photography_iPhone_Vertical_01",
          "videoName": "生成器/V1-0003_3.mp4",
          "classify": "Generator"
        },
        {
          "name": "Photography_iPhone_Vertical_02",
          "videoName": "生成器/V1-0004_4.mp4",
          "classify": "Generator"
        },
        {
          "name": "Photography_SLR",
          "videoName": "生成器/V1-0005_5.mp4",
          "classify": "Generator"
        },
        {
          "name": "Starlight",
          "videoName": "生成器/V1-0006_6.mp4",
          "classify": "Generator"
        },
        {
          "name": "Gradient",
          "videoName": "生成器/V1-0007_7.mp4",
          "classify": "Generator"
        },
        {
          "name": "Photo Frame",
          "videoName": "生成器/V1-0008_8.mp4",
          "classify": "Generator"
        },
        {
          "name": "Photo Frame_Text",
          "videoName": "生成器/V1-0009_9.mp4",
          "classify": "Generator"
        },
        {
          "name": "Film Border Tool",
          "videoName": "生成器/V1-0010_10.mp4",
          "classify": "Generator"
        },
        {
          "name": "Assistant_Symmetric Position",
          "videoName": "生成器/V1-0011_11.mp4",
          "classify": "Generator"
        },
        {
          "name": "Assistant_Row_Column",
          "videoName": "生成器/V1-0012_12.mp4",
          "classify": "Generator"
        },
        {
          "name": "Assistant_Angle",
          "videoName": "生成器/V1-0013_13.mp4",
          "classify": "Generator"
        },
        {
          "name": "Assistant_Distance",
          "videoName": "生成器/V1-0014_14.mp4",
          "classify": "Generator"
        },
        {
          "name": "Assistant_Golden Ratio",
          "videoName": "生成器/V1-0015_15.mp4",
          "classify": "Generator"
        },
        {
          "name": "Speed Lines_01",
          "videoName": "生成器/V1-0016_16.mp4",
          "classify": "Generator"
        },
        {
          "name": "Speed Lines_02",
          "videoName": "生成器/V1-0017_17.mp4",
          "classify": "Generator"
        },
        {
          "name": "Speed Lines_Waterfall",
          "videoName": "生成器/V1-0018_18.mp4",
          "classify": "Generator"
        },
        {
          "name": "Speed Lines_Crossing",
          "videoName": "生成器/V1-0019_19.mp4",
          "classify": "Generator"
        },
        {
          "name": "Highlight Text",
          "videoName": "生成器/V1-0020_20.mp4",
          "classify": "Generator"
        },
        {
          "name": "Typewriter_V2",
          "videoName": "标题/V1-0001_.00000000.mp4",
          "classify": "Title"
        },
        {
          "name": "KTV_Title_Single Line",
          "videoName": "标题/V1-0002_.00000000.mp4",
          "classify": "Title"
        },
        {
          "name": "KTV_Title_Double Line",
          "videoName": "标题/V1-0003_.00000000.mp4",
          "classify": "Title"
        },
        {
          "name": "PTMC_Title",
          "videoName": "标题/V1-0004_.00000000.mp4",
          "classify": "Title"
        },
        {
          "name": "Rounded Text",
          "videoName": "标题/V1-0005_.00000000.mp4",
          "classify": "Title"
        },
        {
          "name": "Vertical Text_V2",
          "videoName": "标题/V1-0006_.00000000.mp4",
          "classify": "Title"
        },
        {
          "name": "Counter",
          "videoName": "标题/V1-0007_.00000000.mp4",
          "classify": "Title"
        },
        {
          "name": "Super Transition_Zoom_In & In",
          "videoName": "转场/V1-0001_1.mp4",
          "classify": "Transition"
        },
        {
          "name": "Super Transition_Zoom_In & Out",
          "videoName": "转场/V1-0002_2.mp4",
          "classify": "Transition"
        },
        {
          "name": "Super Transition_Zoom_Out & In",
          "videoName": "转场/V1-0003_3.mp4",
          "classify": "Transition"
        },
        {
          "name": "Super Transition_Zoom_Out & Out",
          "videoName": "转场/V1-0004_4.mp4",
          "classify": "Transition"
        },
        {
          "name": "Super Transition_Spin",
          "videoName": "转场/V1-0005_5.mp4",
          "classify": "Transition"
        },
        {
          "name": "Super Transition_Slide",
          "videoName": "转场/V1-0006_6.mp4",
          "classify": "Transition"
        },
        {
          "name": "Super Transition_Blend_Blend",
          "videoName": "转场/V1-0007_7.mp4",
          "classify": "Transition"
        },
        {
          "name": "Super Transition_Blend_Erosion",
          "videoName": "转场/V1-0008_8.mp4",
          "classify": "Transition"
        },
        {
          "name": "Super Transition_Blend_Noise",
          "videoName": "转场/V1-0009_9.mp4",
          "classify": "Transition"
        },
        {
          "name": "Super Transition_Zoom_Spin",
          "videoName": "转场/V1-0010_10.mp4",
          "classify": "Transition"
        },
        {
          "name": "Super Transition_Zoom_Slide",
          "videoName": "转场/V1-0011_11.mp4",
          "classify": "Transition"
        },
        {
          "name": "Glitch_01",
          "videoName": "转场/V1-0012_12.mp4",
          "classify": "Transition"
        },
        {
          "name": "Glitch_02",
          "videoName": "转场/V1-0013_13.mp4",
          "classify": "Transition"
        },
        {
          "name": "Glitch_03",
          "videoName": "转场/V1-0014_14.mp4",
          "classify": "Transition"
        },
        {
          "name": "Glitch_04",
          "videoName": "转场/V1-0015_15.mp4",
          "classify": "Transition"
        },
        {
          "name": "Glitch_05",
          "videoName": "转场/V1-0016_16.mp4",
          "classify": "Transition"
        },
      ],
      Batch_Copy_Tools: [],
      Subtitle_Tools: [
        {
          "name": "Subtitle Dashboard",
          "videoName": "V1-0001_.00000000.mp4",
          newTemplate: true
        },
        {
          "name": "Subtitle Base",
          "videoName": "V1-0002_.00000000.mp4",
          newTemplate: true
        },
        {
          "name": "Subtitle Solid Line",
          "videoName": "V1-0003_.00000000.mp4",
          newTemplate: true
        },
        {
          "name": "Subtitle Handraw",
          "videoName": "V1-0004_.00000000.mp4",
          newTemplate: true
        },
        {
          "name": "Subtitle Handraw Border",
          "videoName": "V1-0005_.00000000.mp4",
          newTemplate: true
        },
        {
          "name": "Subtitle Torn Paper",
          "videoName": "V1-0006_.00000000.mp4",
          newTemplate: true
        },
        {
          "name": "Subtitle Glitch",
          "videoName": "V1-0007_.00000000.mp4",
          newTemplate: true
        },
        {
          "name": "Subtitle Wavy Line",
          "videoName": "V1-0008_.00000000.mp4",
          newTemplate: true
        }
      ],
      Slide_Motions_Templates: [
        {
          "name": "Vert 1x2 01",
          "videoName": "特效/多屏滑动/V1-0001_C1.mp4",
          "classify": "Effects-Multi Screen Slide"
        },
        {
          "name": "Vert 1x2 02",
          "videoName": "特效/多屏滑动/V1-0002_C2.mp4",
          "classify": "Effects-Multi Screen Slide"
        },
        {
          "name": "Vert 1x2 03",
          "videoName": "特效/多屏滑动/V1-0003_C3.mp4",
          "classify": "Effects-Multi Screen Slide"
        },
        {
          "name": "Vert 1x3 01",
          "videoName": "特效/多屏滑动/V1-0004_C4.mp4",
          "classify": "Effects-Multi Screen Slide"
        },
        {
          "name": "Vert 1x3 02",
          "videoName": "特效/多屏滑动/V1-0005_C5.mp4",
          "classify": "Effects-Multi Screen Slide"
        },
        {
          "name": "Vert 1x3 03",
          "videoName": "特效/多屏滑动/V1-0006_C6.mp4",
          "classify": "Effects-Multi Screen Slide"
        },
        {
          "name": "Vert 1xT",
          "videoName": "特效/多屏滑动/V1-0007_C7.mp4",
          "classify": "Effects-Multi Screen Slide"
        },
        {
          "name": "Vert Tx1",
          "videoName": "特效/多屏滑动/V1-0008_C8.mp4",
          "classify": "Effects-Multi Screen Slide"
        },
        {
          "name": "Horz 1x2 01",
          "videoName": "特效/多屏滑动/V1-0009_C9.mp4",
          "classify": "Effects-Multi Screen Slide"
        },
        {
          "name": "Horz 1x2 02",
          "videoName": "特效/多屏滑动/V1-0010_C10.mp4",
          "classify": "Effects-Multi Screen Slide"
        },
        {
          "name": "Horz 1x2 03",
          "videoName": "特效/多屏滑动/V1-0011_C11.mp4",
          "classify": "Effects-Multi Screen Slide"
        },
        {
          "name": "Horz 1x3 01",
          "videoName": "特效/多屏滑动/V1-0012_C12.mp4",
          "classify": "Effects-Multi Screen Slide"
        },
        {
          "name": "Horz 1xT 01",
          "videoName": "特效/多屏滑动/V1-0013_C13.mp4",
          "classify": "Effects-Multi Screen Slide"
        },
        {
          "name": "Horz 1xT 02",
          "videoName": "特效/多屏滑动/V1-0014_C14.mp4",
          "classify": "Effects-Multi Screen Slide"
        },
        {
          "name": "Horz 1xT 03",
          "videoName": "特效/多屏滑动/V1-0015_C15.mp4",
          "classify": "Effects-Multi Screen Slide"
        },
        {
          "name": "Horz Tx1 01",
          "videoName": "特效/多屏滑动/V1-0016_C16.mp4",
          "classify": "Effects-Multi Screen Slide"
        },
        {
          "name": "Horz Tx1 02",
          "videoName": "特效/多屏滑动/V1-0017_C17.mp4",
          "classify": "Effects-Multi Screen Slide"
        },
        {
          "name": "Horz Tx1 03",
          "videoName": "特效/多屏滑动/V1-0018_C18.mp4",
          "classify": "Effects-Multi Screen Slide"
        },
        {
          "name": "Screen Text Horz 01",
          "videoName": "特效/多屏文字横屏/V1-0001_C1.mp4",
          "classify": "Effects-Multi Screen Text Horz"
        },
        {
          "name": "Screen Text Horz 02",
          "videoName": "特效/多屏文字横屏/V1-0002_C2.mp4",
          "classify": "Effects-Multi Screen Text Horz"
        },
        {
          "name": "Screen Text Horz 03",
          "videoName": "特效/多屏文字横屏/V1-0003_C3.mp4",
          "classify": "Effects-Multi Screen Text Horz"
        },
        {
          "name": "Screen Text Horz 04",
          "videoName": "特效/多屏文字横屏/V1-0004_C4.mp4",
          "classify": "Effects-Multi Screen Text Horz"
        },
        {
          "name": "Screen Text Horz 05",
          "videoName": "特效/多屏文字横屏/V1-0005_C5.mp4",
          "classify": "Effects-Multi Screen Text Horz"
        },
        {
          "name": "Screen Text Horz 06",
          "videoName": "特效/多屏文字横屏/V1-0006_C6.mp4",
          "classify": "Effects-Multi Screen Text Horz"
        },
        {
          "name": "Screen Text Horz 07",
          "videoName": "特效/多屏文字横屏/V1-0007_C7.mp4",
          "classify": "Effects-Multi Screen Text Horz"
        },
        {
          "name": "Screen Text Horz 08",
          "videoName": "特效/多屏文字横屏/V1-0008_C8.mp4",
          "classify": "Effects-Multi Screen Text Horz"
        },
        {
          "name": "Screen Text Horz 09",
          "videoName": "特效/多屏文字横屏/V1-0009_C9.mp4",
          "classify": "Effects-Multi Screen Text Horz"
        },
        {
          "name": "Screen Text Horz 10",
          "videoName": "特效/多屏文字横屏/V1-0010_C10.mp4",
          "classify": "Effects-Multi Screen Text Horz"
        },
        {
          "name": "Screen Text Horz 11",
          "videoName": "特效/多屏文字横屏/V1-0011_C11.mp4",
          "classify": "Effects-Multi Screen Text Horz"
        },
        {
          "name": "Screen Text Horz 12",
          "videoName": "特效/多屏文字横屏/V1-0012_C12.mp4",
          "classify": "Effects-Multi Screen Text Horz"
        },
        {
          "name": "Screen Text Horz 13",
          "videoName": "特效/多屏文字横屏/V1-0013_C13.mp4",
          "classify": "Effects-Multi Screen Text Horz"
        },
        {
          "name": "Screen Text Vert 01",
          "videoName": "特效/多屏文字竖屏/V1-0001_V1-0001_C1.mp4",
          "classify": "Effects-Multi Screen Text Vert"
        },
        {
          "name": "Screen Text Vert 02",
          "videoName": "特效/多屏文字竖屏/V1-0002_V1-0002_C2.mp4",
          "classify": "Effects-Multi Screen Text Vert"
        },
        {
          "name": "Screen Text Vert 03",
          "videoName": "特效/多屏文字竖屏/V1-0003_V1-0003_C3.mp4",
          "classify": "Effects-Multi Screen Text Vert"
        },
        {
          "name": "Screen Text Vert 04",
          "videoName": "特效/多屏文字竖屏/V1-0004_V1-0004_C4.mp4",
          "classify": "Effects-Multi Screen Text Vert"
        },
        {
          "name": "Screen Text Vert 05",
          "videoName": "特效/多屏文字竖屏/V1-0005_V1-0005_C5.mp4",
          "classify": "Effects-Multi Screen Text Vert"
        },
        {
          "name": "Screen Text Vert 06",
          "videoName": "特效/多屏文字竖屏/V1-0006_V1-0006_C6.mp4",
          "classify": "Effects-Multi Screen Text Vert"
        },
        {
          "name": "Screen Text Vert 07",
          "videoName": "特效/多屏文字竖屏/V1-0007_V1-0007_C7.mp4",
          "classify": "Effects-Multi Screen Text Vert"
        },
        {
          "name": "Screen Text Vert 08",
          "videoName": "特效/多屏文字竖屏/V1-0008_V1-0008_C8.mp4",
          "classify": "Effects-Multi Screen Text Vert"
        },
        {
          "name": "Slide Motion Horz 01",
          "videoName": "转场/滑动-横屏/V1-0001_C1.mp4",
          "classify": "Transitions-Slide Horizontal"
        },
        {
          "name": "Slide Motion Horz 02",
          "videoName": "转场/滑动-横屏/V1-0002_C2.mp4",
          "classify": "Transitions-Slide Horizontal"
        },
        {
          "name": "Slide Motion Horz 03",
          "videoName": "转场/滑动-横屏/V1-0003_C3.mp4",
          "classify": "Transitions-Slide Horizontal"
        },
        {
          "name": "Slide Motion Horz 04",
          "videoName": "转场/滑动-横屏/V1-0004_C4.mp4",
          "classify": "Transitions-Slide Horizontal"
        },
        {
          "name": "Slide Motion Horz 05",
          "videoName": "转场/滑动-横屏/V1-0005_C5.mp4",
          "classify": "Transitions-Slide Horizontal"
        },
        {
          "name": "Slide Motion Horz 06",
          "videoName": "转场/滑动-横屏/V1-0006_C6.mp4",
          "classify": "Transitions-Slide Horizontal"
        },
        {
          "name": "Slide Motion Horz 07",
          "videoName": "转场/滑动-横屏/V1-0007_C7.mp4",
          "classify": "Transitions-Slide Horizontal"
        },
        {
          "name": "Slide Motion Horz 08",
          "videoName": "转场/滑动-横屏/V1-0008_C8.mp4",
          "classify": "Transitions-Slide Horizontal"
        },
        {
          "name": "Slide Motion Vert 01",
          "videoName": "转场/滑动-竖屏/V1-0001_V1-0001_C1.mp4",
          "classify": "Transitions-Slide Vertical"
        },
        {
          "name": "Slide Motion Vert 02",
          "videoName": "转场/滑动-竖屏/V1-0002_V1-0002_C2.mp4",
          "classify": "Transitions-Slide Vertical"
        },
        {
          "name": "Slide Motion Vert 03",
          "videoName": "转场/滑动-竖屏/V1-0003_V1-0003_C3.mp4",
          "classify": "Transitions-Slide Vertical"
        },
        {
          "name": "Slide Motion Vert 04",
          "videoName": "转场/滑动-竖屏/V1-0004_V1-0004_C4.mp4",
          "classify": "Transitions-Slide Vertical"
        },
        {
          "name": "Slide Motion Vert 05",
          "videoName": "转场/滑动-竖屏/V1-0005_V1-0005_C5.mp4",
          "classify": "Transitions-Slide Vertical"
        },
        {
          "name": "Slide Motion Vert 06",
          "videoName": "转场/滑动-竖屏/V1-0006_V1-0006_C6.mp4",
          "classify": "Transitions-Slide Vertical"
        }
      ],
      CallOut_Templates: [
        {
          name: "Call Out 01",
          videoName: "V2/V1-0001_.00000000.mp4",
          classify: "V2",
          newTemplate: true
        },
        {
          name: "Call Out 02",
          videoName: "V2/V1-0002_.00000000.mp4",
          classify: "V2",
          newTemplate: true
        },
        {
          name: "Call Out 03",
          videoName: "V2/V1-0003_.00000000.mp4",
          classify: "V2",
          newTemplate: true
        },
        {
          name: "Call Out 04",
          videoName: "V2/V1-0004_.00000000.mp4",
          classify: "V2",
          newTemplate: true
        },
        {
          name: "Call Out 05",
          videoName: "V2/V1-0005_.00000000.mp4",
          classify: "V2",
          newTemplate: true
        },
        {
          name: "Call Out 06",
          videoName: "V2/V1-0006_.00000000.mp4",
          classify: "V2",
          newTemplate: true
        },
        {
          name: "Call Out 07",
          videoName: "V2/V1-0007_.00000000.mp4",
          classify: "V2",
          newTemplate: true
        },
        {
          name: "Call Out 08",
          videoName: "V2/V1-0008_.00000000.mp4",
          classify: "V2",
          newTemplate: true
        },
        {
          name: "Call Out 09",
          videoName: "V2/V1-0009_.00000000.mp4",
          classify: "V2",
          newTemplate: true
        },
        {
          name: "Call Out 10",
          videoName: "V2/V1-0010_.00000000.mp4",
          classify: "V2",
          newTemplate: true
        },
        {
          name: "Call Out 11",
          videoName: "V2/V1-0011_.00000000.mp4",
          classify: "V2",
          newTemplate: true
        },
        {
          name: "Call Out 12",
          videoName: "V2/V1-0012_.00000000.mp4",
          classify: "V2",
          newTemplate: true
        },

        {
          name: "Callout_01(V1)",
          videoName: "V4-0001_.00000000.mp4",
          classify: "V1",
        },
        {
          name: "Callout_02(V1)",
          videoName: "V4-0002_.00000000.mp4",
          classify: "V1",
        },
        {
          name: "Callout_03(V1)",
          videoName: "V4-0003_.00000000.mp4",
          classify: "V1",
        },
        {
          name: "Callout_04(V1)",
          videoName: "V4-0004_.00000000.mp4",
          classify: "V1",
        },
        {
          name: "Callout_03_右(V1)",
          videoName: "V4-0005_.00000000.mp4",
          classify: "V1",
        },
        {
          name: "Callout_03_左(V1)",
          videoName: "V4-0006_.00000000.mp4",
          classify: "V1",
        },
        {
          name: "Callout_04_右(V1)",
          videoName: "V4-0007_.00000000.mp4",
          classify: "V1",
        },
        {
          name: "Callout_04_左(V1)",
          videoName: "V4-0008_.00000000.mp4",
          classify: "V1",
        },
        {
          name: "Callout_05_右(V1)",
          videoName: "V4-0009_.00000000.mp4",
          classify: "V1",
        },
        {
          name: "Callout_05_左(V1)",
          videoName: "V4-0010_.00000000.mp4",
          classify: "V1",
        },
        {
          name: "Callout_06_右(V1)",
          videoName: "V4-0011_.00000000.mp4",
          classify: "V1",
        },
        {
          name: "Callout_06_左(V1)",
          videoName: "V4-0012_.00000000.mp4",
          classify: "V1",
        },
        {
          name: "Callout_07_右(V1)",
          videoName: "V4-0013_.00000000.mp4",
          classify: "V1",
        },
        {
          name: "Callout_07_左(V1)",
          videoName: "V4-0014_.00000000.mp4",
          classify: "V1",
        },
        {
          name: "Callout_08_右(V1)",
          videoName: "V4-0015_.00000000.mp4",
          classify: "V1",
        },
        {
          name: "Callout_08_左(V1)",
          videoName: "V4-0016_.00000000.mp4",
          classify: "V1",
        },
        {
          name: "Callout_09_右(V1)",
          videoName: "V4-0017_.00000000.mp4",
          classify: "V1",
        },
        {
          name: "Callout_09_左(V1)",
          videoName: "V4-0018_.00000000.mp4",
          classify: "V1",
        },
        {
          name: "Callout_10_右(V1)",
          videoName: "V4-0019_.00000000.mp4",
          classify: "V1",
        },
        {
          name: "Callout_10_左(V1)",
          videoName: "V4-0020_.00000000.mp4",
          classify: "V1",
        },
        {
          name: "Callout_11_上(V1)",
          videoName: "V4-0021_.00000000.mp4",
          classify: "V1",
        },
        {
          name: "Callout_11_下(V1)",
          videoName: "V4-0022_.00000000.mp4",
          classify: "V1",
        },
        {
          name: "Callout_12_上(V1)",
          videoName: "V4-0023_.00000000.mp4",
          classify: "V1",
        },
        {
          name: "Callout_12_下(V1)",
          videoName: "V4-0024_.00000000.mp4",
          classify: "V1",
        },
      ],
      SocialMedia_Animation_Templates: [
        {
          name: "JD_Circular",
          videoName: "V1-0056_.00000000.mp4",
          newTemplate: true
        },
        {
          name: "JD_Flat",
          videoName: "V1-0057_.00000000.mp4",
          newTemplate: true
        },
        {
          name: "JD_Square",
          videoName: "V1-0058_.00000000.mp4",
          newTemplate: true
        },
        {
          name: "TMall_Circular",
          videoName: "V1-0059_.00000000.mp4",
          newTemplate: true
        },
        {
          name: "TMall_Flat",
          videoName: "V1-0060_.00000000.mp4",
          newTemplate: true
        },
        {
          name: "TMall_Square",
          videoName: "V1-0061_.00000000.mp4",
          newTemplate: true
        },
        {
          name: "Xiaohongshu_Interface_Cover",
          videoName: "V1-0062_.00000000.mp4",
          newTemplate: true
        },
        {
          "name": "Facebook_Circular",
          "videoName": "V1-0001_.00000000.mp4"
        },
        {
          "name": "Facebook_Flat",
          "videoName": "V1-0002_.00000000.mp4"
        },
        {
          "name": "Facebook_Square",
          "videoName": "V1-0003_.00000000.mp4"
        },
        {
          "name": "Google_Plus_Square",
          "videoName": "V1-0004_.00000000.mp4"
        },
        {
          "name": "Google_Plus_Circular",
          "videoName": "V1-0005_.00000000.mp4"
        },
        {
          "name": "Google_Plus_Flat",
          "videoName": "V1-0006_.00000000.mp4"
        },
        {
          "name": "Instagram_Circular",
          "videoName": "V1-0007_.00000000.mp4"
        },
        {
          "name": "Instagram_Flat",
          "videoName": "V1-0008_.00000000.mp4"
        },
        {
          "name": "Instagram_Square",
          "videoName": "V1-0009_.00000000.mp4"
        },
        {
          "name": "QQ_Circular",
          "videoName": "V1-0010_.00000000.mp4"
        },
        {
          "name": "QQ_Flat",
          "videoName": "V1-0011_.00000000.mp4"
        },
        {
          "name": "QQ_Square",
          "videoName": "V1-0012_.00000000.mp4"
        },
        {
          "name": "Twitter_Circular",
          "videoName": "V1-0013_.00000000.mp4"
        },
        {
          "name": "Twitter_Flat",
          "videoName": "V1-0014_.00000000.mp4"
        },
        {
          "name": "Twitter_Square",
          "videoName": "V1-0015_.00000000.mp4"
        },
        {
          "name": "Youtube_Circular",
          "videoName": "V1-0016_.00000000.mp4"
        },
        {
          "name": "Youtube_Flat",
          "videoName": "V1-0017_.00000000.mp4"
        },
        {
          "name": "Youtube_Square",
          "videoName": "V1-0018_.00000000.mp4"
        },
        {
          "name": "Youtube_Subscribe_Like_Share",
          "videoName": "V1-0019_.00000000.mp4"
        },
        {
          "name": "Youtube_Subscribe_Like_Share_Simplified",
          "videoName": "V1-0020_.00000000.mp4"
        },
        {
          "name": "Bilibili_One_Click_Three_Connect",
          "videoName": "V1-0021_.00000000.mp4"
        },
        {
          "name": "Bilibili_One_Click_Three_Connect_Simplified",
          "videoName": "V1-0022_.00000000.mp4"
        },
        {
          "name": "Bilibili_Circular",
          "videoName": "V1-0023_.00000000.mp4"
        },
        {
          "name": "Bilibili_Flat",
          "videoName": "V1-0024_.00000000.mp4"
        },
        {
          "name": "Bilibili_Square",
          "videoName": "V1-0025_.00000000.mp4"
        },
        {
          "name": "Xiaohongshu_Circular",
          "videoName": "V1-0026_.00000000.mp4"
        },
        {
          "name": "Xiaohongshu_Flat",
          "videoName": "V1-0027_.00000000.mp4"
        },
        {
          "name": "Xiaohongshu_Square",
          "videoName": "V1-0028_.00000000.mp4"
        },
        {
          "name": "WeChat_Circular",
          "videoName": "V1-0029_.00000000.mp4"
        },
        {
          "name": "WeChat_Flat",
          "videoName": "V1-0030_.00000000.mp4"
        },
        {
          "name": "WeChat_Square",
          "videoName": "V1-0031_.00000000.mp4"
        },
        {
          "name": "Weibo_Circular",
          "videoName": "V1-0032_.00000000.mp4"
        },
        {
          "name": "Weibo_Flat",
          "videoName": "V1-0033_.00000000.mp4"
        },
        {
          "name": "Weibo_Square",
          "videoName": "V1-0034_.00000000.mp4"
        },
        {
          "name": "Kuaishou_Interface_Cover",
          "videoName": "V1-0035_.00000000.mp4"
        },
        {
          "name": "Douyin_Circular",
          "videoName": "V1-0036_.00000000.mp4"
        },
        {
          "name": "Douyin_Avatar_Share_1",
          "videoName": "V1-0037_.00000000.mp4"
        },
        {
          "name": "Douyin_Avatar_Share_2",
          "videoName": "V1-0038_.00000000.mp4"
        },
        {
          "name": "Douyin_Flat",
          "videoName": "V1-0039_.00000000.mp4"
        },
        {
          "name": "Douyin_Square",
          "videoName": "V1-0040_.00000000.mp4"
        },
        {
          "name": "Douyin_Interface_Cover",
          "videoName": "V1-0041_.00000000.mp4"
        },
        {
          "name": "Taobao_Circular",
          "videoName": "V1-0042_.00000000.mp4"
        },
        {
          "name": "Taobao_Flat",
          "videoName": "V1-0043_.00000000.mp4"
        },
        {
          "name": "Taobao_Square",
          "videoName": "V1-0044_.00000000.mp4"
        },
        {
          "name": "Chat_Bubble_Right",
          "videoName": "V1-0045_.00000000.mp4"
        },
        {
          "name": "Chat_Bubble_Left",
          "videoName": "V1-0046_.00000000.mp4"
        },
        {
          "name": "Custom_Circular",
          "videoName": "V1-0047_.00000000.mp4"
        },
        {
          "name": "Custom_Flat",
          "videoName": "V1-0048_.00000000.mp4"
        },
        {
          "name": "Custom_Square",
          "videoName": "V1-0049_.00000000.mp4"
        },
        {
          "name": "Custom_Like_Share_Simplified",
          "videoName": "V1-0050_.00000000.mp4"
        },
        {
          "name": "Watermelon_Video_Circular",
          "videoName": "V1-0051_.00000000.mp4"
        },
        {
          "name": "Watermelon_Video_Flat",
          "videoName": "V1-0052_.00000000.mp4"
        },
        {
          "name": "Watermelon_Video_Square",
          "videoName": "V1-0053_.00000000.mp4"
        },
        {
          "name": "Watermelon_Video_Like_Share",
          "videoName": "V1-0054_.00000000.mp4"
        },
        {
          "name": "Watermelon_Video_Like_Share_Simplified",
          "videoName": "V1-0055_.00000000.mp4"
        }
      ],
      Glitch_Lower_Thirds_30FPS: [
        {
          "name": "Glitch Text 01",
          "videoName": "V1-0001_.00000000.mp4"
        },
        {
          "name": "Glitch Text 02",
          "videoName": "V1-0002_.00000000.mp4"
        },
        {
          "name": "Glitch Text 03",
          "videoName": "V1-0003_.00000000.mp4"
        },
        {
          "name": "Glitch Text 04",
          "videoName": "V1-0004_.00000000.mp4"
        },
        {
          "name": "Glitch Text 05",
          "videoName": "V1-0005_.00000000.mp4"
        },
        {
          "name": "Glitch Block 01",
          "videoName": "V1-0006_.00000000.mp4"
        },
        {
          "name": "Glitch Block 02",
          "videoName": "V1-0007_.00000000.mp4"
        },
        {
          "name": "Glitch Block 03",
          "videoName": "V1-0008_.00000000.mp4"
        },
        {
          "name": "Glitch Block 04",
          "videoName": "V1-0009_.00000000.mp4"
        },
        {
          "name": "Glitch Block 05",
          "videoName": "V1-0010_.00000000.mp4"
        },
        {
          "name": "Glitch Line 01",
          "videoName": "V1-0011_.00000000.mp4"
        },
        {
          "name": "Glitch Line 02",
          "videoName": "V1-0012_.00000000.mp4"
        },
        {
          "name": "Glitch Line 03",
          "videoName": "V1-0013_.00000000.mp4"
        },
        {
          "name": "Glitch Line 04",
          "videoName": "V1-0014_.00000000.mp4"
        },
        {
          "name": "Glitch Line 05",
          "videoName": "V1-0015_.00000000.mp4"
        },
      ],
      Glitch_Lower_Thirds_60FPS: [
        {
          "name": "Glitch Text 01 High Framerate",
          "videoName": "V1-0001_.00000000.mp4"
        },
        {
          "name": "Glitch Text 02 High Framerate",
          "videoName": "V1-0002_.00000000.mp4"
        },
        {
          "name": "Glitch Text 03 High Framerate",
          "videoName": "V1-0003_.00000000.mp4"
        },
        {
          "name": "Glitch Text 04 High Framerate",
          "videoName": "V1-0004_.00000000.mp4"
        },
        {
          "name": "Glitch Text 05 High Framerate",
          "videoName": "V1-0005_.00000000.mp4"
        },
        {
          "name": "Glitch Block 01 High Framerate",
          "videoName": "V1-0006_.00000000.mp4"
        },
        {
          "name": "Glitch Block 02 High Framerate",
          "videoName": "V1-0007_.00000000.mp4"
        },
        {
          "name": "Glitch Block 03 High Framerate",
          "videoName": "V1-0008_.00000000.mp4"
        },
        {
          "name": "Glitch Block 04 High Framerate",
          "videoName": "V1-0009_.00000000.mp4"
        },
        {
          "name": "Glitch Block 05 High Framerate",
          "videoName": "V1-0010_.00000000.mp4"
        },
        {
          "name": "Glitch Line 01 High Framerate",
          "videoName": "V1-0011_.00000000.mp4"
        },
        {
          "name": "Glitch Line 02 High Framerate",
          "videoName": "V1-0012_.00000000.mp4"
        },
        {
          "name": "Glitch Line 03 High Framerate",
          "videoName": "V1-0013_.00000000.mp4"
        },
        {
          "name": "Glitch Line 04 High Framerate",
          "videoName": "V1-0014_.00000000.mp4"
        },
        {
          "name": "Glitch Line 05 High Framerate",
          "videoName": "V1-0015_.00000000.mp4"
        },
      ],
      Innovation_Titles_Templates: [
        {
          "name": "Block Side Title 01",
          "videoName": "V1-0001_.00000000.mp4"
        },
        {
          "name": "Block Side Title 02",
          "videoName": "V1-0002_.00000000.mp4"
        },
        {
          "name": "Block Side Title 03",
          "videoName": "V1-0003_.00000000.mp4"
        },
        {
          "name": "Block Single Line Title 01",
          "videoName": "V1-0004_.00000000.mp4"
        },
        {
          "name": "Block Single Line Title 02",
          "videoName": "V1-0005_.00000000.mp4"
        },
        {
          "name": "Block Single Line Title 03",
          "videoName": "V1-0006_.00000000.mp4"
        },
        {
          "name": "Block Double Line Title 01",
          "videoName": "V1-0007_.00000000.mp4"
        },
        {
          "name": "Block Double Line Title 02",
          "videoName": "V1-0008_.00000000.mp4"
        },
        {
          "name": "Block Double Line Title 03",
          "videoName": "V1-0009_.00000000.mp4"
        },
        {
          "name": "Block Double Line Title 04",
          "videoName": "V1-0010_.00000000.mp4"
        },
        {
          "name": "Block Double Line Title 05",
          "videoName": "V1-0011_.00000000.mp4"
        },
        {
          "name": "Line Segment Merge Double Line Title",
          "videoName": "V1-0012_.00000000.mp4"
        },
        {
          "name": "Line Segment Center Separation Double Line Title",
          "videoName": "V1-0013_.00000000.mp4"
        },
        {
          "name": "Line Segment Expand Double Line Title 01",
          "videoName": "V1-0014_.00000000.mp4"
        },
        {
          "name": "Line Segment Expand Double Line Title 02",
          "videoName": "V1-0015_.00000000.mp4"
        },
        {
          "name": "Line Segment Expand Double Line Title 03",
          "videoName": "V1-0016_.00000000.mp4"
        },
        {
          "name": "Line Segment Expand Double Line Title 04",
          "videoName": "V1-0017_.00000000.mp4"
        },
        {
          "name": "Line Segment Slide Single Line Title 01",
          "videoName": "V1-0018_.00000000.mp4"
        },
        {
          "name": "Line Segment Slide Single Line Title 02",
          "videoName": "V1-0019_.00000000.mp4"
        },
        {
          "name": "Line Segment Slide Double Line Title",
          "videoName": "V1-0020_.00000000.mp4"
        },
        {
          "name": "Line Segment Rotate Double Line Title 01",
          "videoName": "V1-0021_.00000000.mp4"
        },
        {
          "name": "Line Segment Rotate Double Line Title 02",
          "videoName": "V1-0022_.00000000.mp4"
        },
      ],
      Wedding_Titles_Template: [
        {
          "name": "Wedding Title 01",
          "videoName": "V1-0001_.00000000.mp4"
        },
        {
          "name": "Wedding Title 02",
          "videoName": "V1-0002_.00000000.mp4"
        },
        {
          "name": "Wedding Title 03",
          "videoName": "V1-0003_.00000000.mp4"
        },
        {
          "name": "Wedding Title 04",
          "videoName": "V1-0004_.00000000.mp4"
        },
        {
          "name": "Wedding Title 05",
          "videoName": "V1-0005_.00000000.mp4"
        },
        {
          "name": "Wedding Title 06",
          "videoName": "V1-0006_.00000000.mp4"
        },
        {
          "name": "Wedding Title 07",
          "videoName": "V1-0007_.00000000.mp4"
        },
        {
          "name": "Wedding Title 08",
          "videoName": "V1-0008_.00000000.mp4"
        },
        {
          "name": "Wedding Title 09",
          "videoName": "V1-0009_.00000000.mp4"
        },
        {
          "name": "Wedding Title 10",
          "videoName": "V1-0010_.00000000.mp4"
        },
        {
          "name": "Wedding Title 11",
          "videoName": "V1-0011_.00000000.mp4"
        },
        {
          "name": "Wedding Title 12",
          "videoName": "V1-0012_.00000000.mp4"
        },
        {
          "name": "Wedding Title 13",
          "videoName": "V1-0013_.00000000.mp4"
        },
        {
          "name": "Wedding Title 14",
          "videoName": "V1-0014_.00000000.mp4"
        },
        {
          "name": "Wedding Title 15",
          "videoName": "V1-0015_.00000000.mp4"
        },
        {
          "name": "Wedding Title 16",
          "videoName": "V1-0016_.00000000.mp4"
        },
        {
          "name": "Wedding Title 17",
          "videoName": "V1-0017_.00000000.mp4"
        },
        {
          "name": "Wedding Title 18",
          "videoName": "V1-0018_.00000000.mp4"
        },
        {
          "name": "Wedding Title 19",
          "videoName": "V1-0019_.00000000.mp4"
        },
        {
          "name": "Wedding Title 20",
          "videoName": "V1-0020_.00000000.mp4"
        },
        {
          "name": "Wedding Title 21",
          "videoName": "V1-0021_.00000000.mp4"
        },
        {
          "name": "Wedding Title 22",
          "videoName": "V1-0022_.00000000.mp4"
        },
        {
          "name": "Wedding Title 23",
          "videoName": "V1-0023_.00000000.mp4"
        },
        {
          "name": "Wedding Title 24",
          "videoName": "V1-0024_.00000000.mp4"
        },
      ],
      Brush_Paint_Titles_Template: [
        {
          "name": "Oil Paint 01",
          "videoName": "V1-0001_.00000000.mp4"
        },
        {
          "name": "Oil Paint 02",
          "videoName": "V1-0002_.00000000.mp4"
        },
        {
          "name": "Oil Paint 03",
          "videoName": "V1-0003_.00000000.mp4"
        },
        {
          "name": "Oil Paint 04",
          "videoName": "V1-0004_.00000000.mp4"
        },
        {
          "name": "Oil Paint 05",
          "videoName": "V1-0005_.00000000.mp4"
        },
        {
          "name": "Oil Paint 06",
          "videoName": "V1-0006_.00000000.mp4"
        },
        {
          "name": "Oil Paint Gradient 01",
          "videoName": "V1-0007_.00000000.mp4"
        },
        {
          "name": "Oil Paint Gradient 02",
          "videoName": "V1-0008_.00000000.mp4"
        },
        {
          "name": "Oil Paint Gradient 03",
          "videoName": "V1-0009_.00000000.mp4"
        },
        {
          "name": "Oil Paint Gradient 04",
          "videoName": "V1-0010_.00000000.mp4"
        },
        {
          "name": "Oil Paint Gradient 05",
          "videoName": "V1-0011_.00000000.mp4"
        },
        {
          "name": "Oil Paint Gradient 06",
          "videoName": "V1-0012_.00000000.mp4"
        },
        {
          "name": "Oil Paint Gradient 07",
          "videoName": "V1-0013_.00000000.mp4"
        },
        {
          "name": "Oil Paint Gradient 08",
          "videoName": "V1-0014_.00000000.mp4"
        },
        {
          "name": "Oil Paint Gradient 09",
          "videoName": "V1-0015_.00000000.mp4"
        },
        {
          "name": "Brush 01",
          "videoName": "V1-0016_.00000000.mp4"
        },
        {
          "name": "Brush 02",
          "videoName": "V1-0017_.00000000.mp4"
        },
        {
          "name": "Brush 03",
          "videoName": "V1-0018_.00000000.mp4"
        },
        {
          "name": "Brush 04",
          "videoName": "V1-0019_.00000000.mp4"
        },
        {
          "name": "Brush 05",
          "videoName": "V1-0020_.00000000.mp4"
        },
        {
          "name": "Brush 06",
          "videoName": "V1-0021_.00000000.mp4"
        },
        {
          "name": "Brush 07",
          "videoName": "V1-0022_.00000000.mp4"
        },
        {
          "name": "Brush 08",
          "videoName": "V1-0023_.00000000.mp4"
        },
        {
          "name": "Brush 09",
          "videoName": "V1-0024_.00000000.mp4"
        },
        {
          "name": "Brush 10",
          "videoName": "V1-0025_.00000000.mp4"
        },
        {
          "name": "Brush 11",
          "videoName": "V1-0026_.00000000.mp4"
        },
        {
          "name": "Brush Box 01",
          "videoName": "V1-0027_.00000000.mp4"
        },
        {
          "name": "Brush Box 02",
          "videoName": "V1-0028_.00000000.mp4"
        },
      ],
      Cinematic_Titles_Template: [
        {
          name: "Movie Title 32",
          videoName: "V1-0040_.00000000.mp4",
          classify: "Title",
          newTemplate: true

        },
        {
          name: "Movie Title 33",
          videoName: "V1-0041_.00000000.mp4",
          classify: "Title",
          newTemplate: true
        },
        {
          "name": "Movie Title 01",
          "videoName": "V1-0001_.00000000.mp4",
          "classify": "Title"
        },
        {
          "name": "Movie Title 02",
          "videoName": "V1-0002_.00000000.mp4",
          "classify": "Title"
        },
        {
          "name": "Movie Title 03",
          "videoName": "V1-0003_.00000000.mp4",
          "classify": "Title"
        },
        {
          "name": "Movie Title 04",
          "videoName": "V1-0004_.00000000.mp4",
          "classify": "Title"
        },
        {
          "name": "Movie Title 05",
          "videoName": "V1-0005_.00000000.mp4",
          "classify": "Title"
        },
        {
          "name": "Movie Title 06",
          "videoName": "V1-0006_.00000000.mp4",
          "classify": "Title"
        },
        {
          "name": "Movie Title 07",
          "videoName": "V1-0007_.00000000.mp4",
          "classify": "Title"
        },
        {
          "name": "Movie Title 08",
          "videoName": "V1-0008_.00000000.mp4",
          "classify": "Title"
        },
        {
          "name": "Movie Title 09",
          "videoName": "V1-0009_.00000000.mp4",
          "classify": "Title"
        },
        {
          "name": "Movie Title 10",
          "videoName": "V1-0010_.00000000.mp4",
          "classify": "Title"
        },
        {
          "name": "Movie Title 11",
          "videoName": "V1-0011_.00000000.mp4",
          "classify": "Title"
        },
        {
          "name": "Movie Title 12",
          "videoName": "V1-0012_.00000000.mp4",
          "classify": "Title"
        },
        {
          "name": "Movie Title 13",
          "videoName": "V1-0013_.00000000.mp4",
          "classify": "Title"
        },
        {
          "name": "Movie Title 14",
          "videoName": "V1-0014_.00000000.mp4",
          "classify": "Title"
        },
        {
          "name": "Movie Title 15",
          "videoName": "V1-0015_.00000000.mp4",
          "classify": "Title"
        },
        {
          "name": "Movie Title 16",
          "videoName": "V1-0016_.00000000.mp4",
          "classify": "Title"
        },
        {
          "name": "Movie Title 17",
          "videoName": "V1-0017_.00000000.mp4",
          "classify": "Title"
        },
        {
          "name": "Movie Title 18",
          "videoName": "V1-0018_.00000000.mp4",
          "classify": "Title"
        },
        {
          "name": "Movie Title 19",
          "videoName": "V1-0019_.00000000.mp4",
          "classify": "Title"
        },
        {
          "name": "Movie Title 20",
          "videoName": "V1-0020_.00000000.mp4",
          "classify": "Title"
        },
        {
          "name": "Movie Title 21",
          "videoName": "V1-0021_.00000000.mp4",
          "classify": "Title"
        },
        {
          "name": "Movie Title 22",
          "videoName": "V1-0022_.00000000.mp4",
          "classify": "Title"
        },
        {
          "name": "Movie Title 23",
          "videoName": "V1-0023_.00000000.mp4",
          "classify": "Title"
        },
        {
          "name": "Movie Title 24",
          "videoName": "V1-0024_.00000000.mp4",
          "classify": "Title"
        },
        {
          "name": "Movie Title 25",
          "videoName": "V1-0025_.00000000.mp4",
          "classify": "Title"
        },
        {
          "name": "Movie Title 26",
          "videoName": "V1-0026_.00000000.mp4",
          "classify": "Title"
        },
        {
          "name": "Movie Title 27",
          "videoName": "V1-0027_.00000000.mp4",
          "classify": "Title"
        },
        {
          "name": "Movie Title 28",
          "videoName": "V1-0028_.00000000.mp4",
          "classify": "Title"
        },
        {
          "name": "Movie Title 29",
          "videoName": "V1-0029_.00000000.mp4",
          "classify": "Title"
        },
        {
          "name": "Movie Title 30",
          "videoName": "V1-0030_.00000000.mp4",
          "classify": "Title"
        },
        {
          "name": "Movie Title 31",
          "videoName": "V1-0031_.00000000.mp4",
          "classify": "Title"
        },
        {
          "name": "Vignette",
          "videoName": "V1-0032_.00000000.mp4",
          "classify": "Special Effects"
        },
        {
          "name": "Blur",
          "videoName": "V1-0033_.00000000.mp4",
          "classify": "Special Effects"
        },
        {
          "name": "Film Stock 01",
          "videoName": "V1-0034_.00000000.mp4",
          "classify": "Special Effects"
        },
        {
          "name": "Film Stock 02",
          "videoName": "V1-0035_.00000000.mp4",
          "classify": "Special Effects"
        },
        {
          "name": "Film Stock 03",
          "videoName": "V1-0036_.00000000.mp4",
          "classify": "Special Effects"
        },
        {
          "name": "Film Grain",
          "videoName": "V1-0037_.00000000.mp4",
          "classify": "Special Effects"
        },
        {
          "name": "Dirty Film",
          "videoName": "V1-0038_.00000000.mp4",
          "classify": "Special Effects"
        },
        {
          "name": "Flicker",
          "videoName": "V1-0039_.00000000.mp4",
          "classify": "Special Effects"
        },
      ],
      Creative_Titles_Templates: [
        {
          "name": "Creative_Business_01",
          "videoName": "V1-0001_.00000000.mp4"
        },
        {
          "name": "Creative_Business_02",
          "videoName": "V1-0002_.00000000.mp4"
        },
        {
          "name": "Creative_Business_03",
          "videoName": "V1-0003_.00000000.mp4"
        },
        {
          "name": "Creative_Business_04",
          "videoName": "V1-0004_.00000000.mp4"
        },
        {
          "name": "Creative_Business_05",
          "videoName": "V1-0005_.00000000.mp4"
        },
        {
          "name": "Creative_Business_06",
          "videoName": "V1-0006_.00000000.mp4"
        },
        {
          "name": "Creative_Business_07",
          "videoName": "V1-0007_.00000000.mp4"
        },
        {
          "name": "Creative_Business_08",
          "videoName": "V1-0008_.00000000.mp4"
        },
        {
          "name": "Creative_Business_09",
          "videoName": "V1-0009_.00000000.mp4"
        },
        {
          "name": "Creative_Business_10",
          "videoName": "V1-0010_.00000000.mp4"
        },
        {
          "name": "Creative_Fashion_01",
          "videoName": "V1-0011_.00000000.mp4"
        },
        {
          "name": "Creative_Fashion_02",
          "videoName": "V1-0012_.00000000.mp4"
        },
        {
          "name": "Creative_Fashion_03",
          "videoName": "V1-0013_.00000000.mp4"
        },
        {
          "name": "Creative_Fashion_04",
          "videoName": "V1-0014_.00000000.mp4"
        },
        {
          "name": "Creative_Fashion_05",
          "videoName": "V1-0015_.00000000.mp4"
        },
        {
          "name": "Creative_Fashion_06",
          "videoName": "V1-0016_.00000000.mp4"
        },
        {
          "name": "Creative_Fashion_07",
          "videoName": "V1-0017_.00000000.mp4"
        },
        {
          "name": "Creative_Fashion_08",
          "videoName": "V1-0018_.00000000.mp4"
        },
        {
          "name": "Creative_Fashion_09",
          "videoName": "V1-0019_.00000000.mp4"
        },
        {
          "name": "Creative_Fashion_10",
          "videoName": "V1-0020_.00000000.mp4"
        },
        {
          "name": "Creative_Simple_01",
          "videoName": "V1-0021_.00000000.mp4"
        },
        {
          "name": "Creative_Simple_02",
          "videoName": "V1-0022_.00000000.mp4"
        },
        {
          "name": "Creative_Simple_03",
          "videoName": "V1-0023_.00000000.mp4"
        },
        {
          "name": "Creative_Simple_04",
          "videoName": "V1-0024_.00000000.mp4"
        },
        {
          "name": "Creative_Simple_05",
          "videoName": "V1-0025_.00000000.mp4"
        },
        {
          "name": "Creative_Simple_06",
          "videoName": "V1-0026_.00000000.mp4"
        },
        {
          "name": "Creative_Simple_07",
          "videoName": "V1-0027_.00000000.mp4"
        },
        {
          "name": "Creative_Simple_08",
          "videoName": "V1-0028_.00000000.mp4"
        },
        {
          "name": "Creative_Simple_09",
          "videoName": "V1-0029_.00000000.mp4"
        },
        {
          "name": "Creative_Simple_10",
          "videoName": "V1-0030_.00000000.mp4"
        },
      ],
      Variety_Show_Text_Templates: [
        {
          "name": "Word_Cloud",
          "videoName": "V1-0001_.00000000.mp4"
        },
        {
          "name": "Word_Card_1",
          "videoName": "V1-0002_.00000000.mp4"
        },
        {
          "name": "Word_Card_2",
          "videoName": "V1-0003_.00000000.mp4"
        },
        {
          "name": "Word_Card_3",
          "videoName": "V1-0004_.00000000.mp4"
        },
        {
          "name": "Word_Call_1",
          "videoName": "V1-0005_.00000000.mp4"
        },
        {
          "name": "Word_Call_2",
          "videoName": "V1-0006_.00000000.mp4"
        },
        {
          "name": "Word_Call_3",
          "videoName": "V1-0007_.00000000.mp4"
        },
        {
          "name": "Word_Haha",
          "videoName": "V1-0008_.00000000.mp4"
        },
        {
          "name": "Word_Cry",
          "videoName": "V1-0009_.00000000.mp4"
        },
        {
          "name": "Word_Olégěi",
          "videoName": "V1-0010_.00000000.mp4"
        },
        {
          "name": "Word_Broken_Heart",
          "videoName": "V1-0011_.00000000.mp4"
        },
        {
          "name": "Word_Heartbeat_1",
          "videoName": "V1-0012_.00000000.mp4"
        },
        {
          "name": "Word_Heartbeat_2",
          "videoName": "V1-0013_.00000000.mp4"
        },
        {
          "name": "Word_Surprise_1",
          "videoName": "V1-0014_.00000000.mp4"
        },
        {
          "name": "Word_Surprise_2",
          "videoName": "V1-0015_.00000000.mp4"
        },
        {
          "name": "Word_Surprise_3",
          "videoName": "V1-0016_.00000000.mp4"
        },
        {
          "name": "Word_Style_1_Yellow",
          "videoName": "V1-0017_.00000000.mp4"
        },
        {
          "name": "Word_Style_2_Blue",
          "videoName": "V1-0018_.00000000.mp4"
        },
        {
          "name": "Word_Style_3_Pink",
          "videoName": "V1-0019_.00000000.mp4"
        },
        {
          "name": "Word_Style_3_Purple",
          "videoName": "V1-0020_.00000000.mp4"
        },
        {
          "name": "Word_Style_4_Pink",
          "videoName": "V1-0021_.00000000.mp4"
        },
        {
          "name": "Word_Style_4_Cyberpunk",
          "videoName": "V1-0022_.00000000.mp4"
        },
        {
          "name": "Word_Style_5_Red",
          "videoName": "V1-0023_.00000000.mp4"
        },
        {
          "name": "Word_Style_5_Green",
          "videoName": "V1-0024_.00000000.mp4"
        },
        {
          "name": "Word_Style_5_Yellow",
          "videoName": "V1-0025_.00000000.mp4"
        },
        {
          "name": "Word_Style_6_Sunset",
          "videoName": "V1-0026_.00000000.mp4"
        },
        {
          "name": "Word_Style_6_Blue",
          "videoName": "V1-0027_.00000000.mp4"
        },
        {
          "name": "Word_Style_7_Chalk",
          "videoName": "V1-0028_.00000000.mp4"
        },
        {
          "name": "Word_Bubble_1",
          "videoName": "V1-0029_.00000000.mp4"
        },
        {
          "name": "Word_Bubble_2",
          "videoName": "V1-0030_.00000000.mp4"
        },
        {
          "name": "Word_Bubble_3",
          "videoName": "V1-0031_.00000000.mp4"
        },
        {
          "name": "Word_Bubble_4",
          "videoName": "V1-0032_.00000000.mp4"
        },
        {
          "name": "Word_Bubble_5",
          "videoName": "V1-0033_.00000000.mp4"
        },
        {
          "name": "Word_Bubble_6",
          "videoName": "V1-0034_.00000000.mp4"
        },
        {
          "name": "Word_Bubble_7",
          "videoName": "V1-0035_.00000000.mp4"
        },
        {
          "name": "Word_Comics_Bubble_01",
          "videoName": "V1-0036_.00000000.mp4"
        },
        {
          "name": "Word_Comics_Bubble_02",
          "videoName": "V1-0037_.00000000.mp4"
        },
        {
          "name": "Word_Comics_Bubble_03",
          "videoName": "V1-0038_.00000000.mp4"
        },
        {
          "name": "Word_Comics_Bubble_04",
          "videoName": "V1-0039_.00000000.mp4"
        },
        {
          "name": "Word_Comics_Bubble_05",
          "videoName": "V1-0040_.00000000.mp4"
        },
        {
          "name": "Word_Comics_Bubble_06",
          "videoName": "V1-0041_.00000000.mp4"
        },
        {
          "name": "Word_Comics_Bubble_07",
          "videoName": "V1-0042_.00000000.mp4"
        },
        {
          "name": "Word_Comics_Bubble_08",
          "videoName": "V1-0043_.00000000.mp4"
        },
        {
          "name": "Word_Comics_Bubble_09",
          "videoName": "V1-0044_.00000000.mp4"
        },
        {
          "name": "Word_Comics_Bubble_10",
          "videoName": "V1-0045_.00000000.mp4"
        },
        {
          "name": "Word_Angry",
          "videoName": "V1-0046_.00000000.mp4"
        },
        {
          "name": "Word_Arrow_1",
          "videoName": "V1-0047_.00000000.mp4"
        },
        {
          "name": "Word_Shiny",
          "videoName": "V1-0048_.00000000.mp4"
        },
        {
          "name": "Word_Tea_Time",
          "videoName": "V1-0049_.00000000.mp4"
        },
        {
          "name": "Flower_Small_Hammer",
          "videoName": "V1-0050_.00000000.mp4"
        },
        {
          "name": "Flower_Awkward_Bird",
          "videoName": "V1-0051_.00000000.mp4"
        },
        {
          "name": "Flower_Melancholy",
          "videoName": "V1-0052_.00000000.mp4"
        },
        {
          "name": "Flower_Exclamation_Mark",
          "videoName": "V1-0053_.00000000.mp4"
        },
        {
          "name": "Flower_Surprise",
          "videoName": "V1-0054_.00000000.mp4"
        },
        {
          "name": "Flower_Peach_Heart",
          "videoName": "V1-0055_.00000000.mp4"
        },
        {
          "name": "Flower_Gas",
          "videoName": "V1-0056_.00000000.mp4"
        },
        {
          "name": "Flower_Droplet",
          "videoName": "V1-0057_.00000000.mp4"
        },
        {
          "name": "Flower_Comics_Exclamation_Mark",
          "videoName": "V1-0058_.00000000.mp4"
        },
        {
          "name": "Flower_Comics_Arrow",
          "videoName": "V1-0059_.00000000.mp4"
        },
        {
          "name": "Flower_Arrow_1",
          "videoName": "V1-0060_.00000000.mp4"
        },
        {
          "name": "Flower_Shiny",
          "videoName": "V1-0061_.00000000.mp4"
        },
        {
          "name": "Flower_Lightning",
          "videoName": "V1-0062_.00000000.mp4"
        },
        {
          "name": "Flower_Question_Mark",
          "videoName": "V1-0063_.00000000.mp4"
        },
        {
          "name": "Flower_Shock",
          "videoName": "V1-0064_.00000000.mp4"
        },
      ],
      VChart_Templates: [
        {
          "name": "Line Chart",
          "videoName": "V1-0001_.00000000.mp4"
        },
        {
          "name": "Bar Chart",
          "videoName": "V1-0002_.00000000.mp4"
        },
        {
          "name": "Pie Chart",
          "videoName": "V1-0003_.00000000.mp4"
        },
        {
          "name": "Coordinate System X Axis",
          "videoName": "V1-0004_.00000000.mp4"
        },
        {
          "name": "Coordinate System X Axis Text",
          "videoName": "V1-0005_.00000000.mp4"
        },
        {
          "name": "Coordinate System Y Axis",
          "videoName": "V1-0006_.00000000.mp4"
        },
        {
          "name": "Coordinate System Y Axis Text",
          "videoName": "V1-0007_.00000000.mp4"
        },
        {
          "name": "Line Chart Basic",
          "videoName": "V1-0008_.00000000.mp4"
        },
        {
          "name": "Number Scrolling",
          "videoName": "V1-0009_.00000000.mp4"
        },
        {
          "name": "Bar Chart Single Line Text",
          "videoName": "V1-0010_.00000000.mp4"
        },
        {
          "name": "Bar Chart Horizontal",
          "videoName": "V1-0011_.00000000.mp4"
        },
        {
          "name": "Bar Chart Vertical",
          "videoName": "V1-0012_.00000000.mp4"
        },
        {
          "name": "Bar Chart Random Single Line Text",
          "videoName": "V1-0013_.00000000.mp4"
        },
        {
          "name": "Activity 3 Circle",
          "videoName": "V1-0014_.00000000.mp4"
        },
        {
          "name": "Activity 4 Circle",
          "videoName": "V1-0015_.00000000.mp4"
        },
        {
          "name": "Activity 5 Circle",
          "videoName": "V1-0016_.00000000.mp4"
        },
        {
          "name": "Percentage Circle",
          "videoName": "V1-0017_.00000000.mp4"
        },
        {
          "name": "Percentage Horizontal Bar",
          "videoName": "V1-0018_.00000000.mp4"
        },
        {
          "name": "Percentage Wave",
          "videoName": "V1-0019_.00000000.mp4"
        },
        {
          "name": "Percentage Pie",
          "videoName": "V1-0020_.00000000.mp4"
        },
        {
          "name": "Flip Number",
          "videoName": "V1-0021_.00000000.mp4"
        },
        {
          "name": "Table",
          "videoName": "V1-0022_.00000000.mp4"
        },
        {
          "name": "Timer",
          "videoName": "V1-0023_.00000000.mp4"
        },
        {
          "name": "Timer Scrolling",
          "videoName": "V1-0024_.00000000.mp4"
        },
        {
          "name": "Radar Chart Pentagon",
          "videoName": "V1-0025_.00000000.mp4"
        },
        {
          "name": "Radar Chart Quadrilateral",
          "videoName": "V1-0026_.00000000.mp4"
        },
        {
          "name": "Radar Chart Circle",
          "videoName": "V1-0027_.00000000.mp4"
        },
        {
          "name": "Pie Chart Basic",
          "videoName": "V1-0028_.00000000.mp4"
        },
        {
          "name": "Pie Chart Donut",
          "videoName": "V1-0029_.00000000.mp4"
        },
      ],
      MG_Animation_Templates: [
        {
          name: "check_mark",
          videoName: "V1-0066_.00000000.mp4",
          newTemplate: true
        },
        {
          name: "cross_cancel",
          videoName: "V1-0067_.00000000.mp4",
          newTemplate: true
        },
        {
          name: "wavy_lines",
          videoName: "V1-0068_.00000000.mp4",
          newTemplate: true
        },
        {
          "name": "Loading Circle 1",
          "videoName": "V1-0001_.00000000.mp4"
        },
        {
          "name": "Loading Circle 2",
          "videoName": "V1-0002_.00000000.mp4"
        },
        {
          "name": "Loading Circle 3",
          "videoName": "V1-0003_.00000000.mp4"
        },
        {
          "name": "Circle Basic",
          "videoName": "V1-0004_.00000000.mp4"
        },
        {
          "name": "Circle Nested",
          "videoName": "V1-0005_.00000000.mp4"
        },
        {
          "name": "Circle Trail",
          "videoName": "V1-0006_.00000000.mp4"
        },
        {
          "name": "Circle Focus",
          "videoName": "V1-0007_.00000000.mp4"
        },
        {
          "name": "Hand-Drawn Circle",
          "videoName": "V1-0008_.00000000.mp4"
        },
        {
          "name": "Hand-Drawn Checkmark",
          "videoName": "V1-0009_.00000000.mp4"
        },
        {
          "name": "Hand-Drawn Arrow 1",
          "videoName": "V1-0010_.00000000.mp4"
        },
        {
          "name": "Hand-Drawn Arrow 2",
          "videoName": "V1-0011_.00000000.mp4"
        },
        {
          "name": "Hand-Drawn Line 1",
          "videoName": "V1-0012_.00000000.mp4"
        },
        {
          "name": "Hand-Drawn Line 2",
          "videoName": "V1-0013_.00000000.mp4"
        },
        {
          "name": "Hand-Drawn Line 3",
          "videoName": "V1-0014_.00000000.mp4"
        },
        {
          "name": "Hand-Drawn Line 4",
          "videoName": "V1-0015_.00000000.mp4"
        },
        {
          "name": "Hand-Drawn Error",
          "videoName": "V1-0016_.00000000.mp4"
        },
        {
          "name": "Hint Label",
          "videoName": "V1-0017_.00000000.mp4"
        },
        {
          "name": "Ruler Basic Vertical",
          "videoName": "V1-0018_.00000000.mp4"
        },
        {
          "name": "Ruler Basic Horizontal",
          "videoName": "V1-0019_.00000000.mp4"
        },
        {
          "name": "Ruler Arrow Vertical",
          "videoName": "V1-0020_.00000000.mp4"
        },
        {
          "name": "Ruler Arrow Horizontal",
          "videoName": "V1-0021_.00000000.mp4"
        },
        {
          "name": "Ruler Shadow Line Vertical",
          "videoName": "V1-0022_.00000000.mp4"
        },
        {
          "name": "Ruler Shadow Line Horizontal",
          "videoName": "V1-0023_.00000000.mp4"
        },
        {
          "name": "Wave Triangle",
          "videoName": "V1-0024_.00000000.mp4"
        },
        {
          "name": "Wave Basic",
          "videoName": "V1-0025_.00000000.mp4"
        },
        {
          "name": "Wave Solid",
          "videoName": "V1-0026_.00000000.mp4"
        },
        {
          "name": "Wave Ripple",
          "videoName": "V1-0027_.00000000.mp4"
        },
        {
          "name": "Wave Rectangle",
          "videoName": "V1-0028_.00000000.mp4"
        },
        {
          "name": "Wave Hollow",
          "videoName": "V1-0029_.00000000.mp4"
        },
        {
          "name": "Fireworks Basic",
          "videoName": "V1-0030_.00000000.mp4"
        },
        {
          "name": "Fireworks Nested",
          "videoName": "V1-0031_.00000000.mp4"
        },
        {
          "name": "Fireworks Nested Rotate Counterclockwise",
          "videoName": "V1-0032_.00000000.mp4"
        },
        {
          "name": "Fireworks Nested Rotate Clockwise",
          "videoName": "V1-0033_.00000000.mp4"
        },
        {
          "name": "Fireworks Counterclockwise",
          "videoName": "V1-0034_.00000000.mp4"
        },
        {
          "name": "Fireworks Clockwise",
          "videoName": "V1-0035_.00000000.mp4"
        },
        {
          "name": "Arrow",
          "videoName": "V1-0036_.00000000.mp4"
        },
        {
          "name": "Arrow Circle Custom",
          "videoName": "V1-0037_.00000000.mp4"
        },
        {
          "name": "Arrow Slide",
          "videoName": "V1-0038_.00000000.mp4"
        },
        {
          "name": "Arrow Rectangle Custom",
          "videoName": "V1-0039_.00000000.mp4"
        },
        {
          "name": "Arrow Line Custom",
          "videoName": "V1-0040_.00000000.mp4"
        },
        {
          "name": "Zoom Triangle",
          "videoName": "V1-0041_.00000000.mp4"
        },
        {
          "name": "Zoom Rectangle",
          "videoName": "V1-0042_.00000000.mp4"
        },
        {
          "name": "Background Image Loop",
          "videoName": "V1-0043_.00000000.mp4"
        },
        {
          "name": "Background Starlight 01",
          "videoName": "V1-0044_.00000000.mp4"
        },
        {
          "name": "Background Starlight 02",
          "videoName": "V1-0045_.00000000.mp4"
        },
        {
          "name": "Background Starlight 03",
          "videoName": "V1-0046_.00000000.mp4"
        },
        {
          "name": "Background Star Trails",
          "videoName": "V1-0047_.00000000.mp4"
        },
        {
          "name": "Background Wave Bicolor",
          "videoName": "V1-0048_.00000000.mp4"
        },
        {
          "name": "Background Rectangle",
          "videoName": "V1-0049_.00000000.mp4"
        },
        {
          "name": "Background Line",
          "videoName": "V1-0050_.00000000.mp4"
        },
        {
          "name": "Background Grid",
          "videoName": "V1-0051_.00000000.mp4"
        },
        {
          "name": "Background Dots",
          "videoName": "V1-0052_.00000000.mp4"
        },
        {
          "name": "Border Text Scroll Landscape",
          "videoName": "V1-0053_.00000000.mp4"
        },
        {
          "name": "Border Text Scroll Portrait",
          "videoName": "V1-0054_.00000000.mp4"
        },
        {
          "name": "Selection Area",
          "videoName": "V1-0055_.00000000.mp4"
        },
        {
          "name": "Selection No Border",
          "videoName": "V1-0056_.00000000.mp4"
        },
        {
          "name": "Selection Flow",
          "videoName": "V1-0057_.00000000.mp4"
        },
        {
          "name": "Selection Focus",
          "videoName": "V1-0058_.00000000.mp4"
        },
        {
          "name": "Selection Dashed",
          "videoName": "V1-0059_.00000000.mp4"
        },
        {
          "name": "Selection Dashed Circle",
          "videoName": "V1-0060_.00000000.mp4"
        },
        {
          "name": "Selection Blink",
          "videoName": "V1-0061_.00000000.mp4"
        },
        {
          "name": "Keyboard Three",
          "videoName": "V1-0062_.00000000.mp4"
        },
        {
          "name": "Keyboard Two",
          "videoName": "V1-0063_.00000000.mp4"
        },
        {
          "name": "Keyboard Two Switch",
          "videoName": "V1-0064_.00000000.mp4"
        },
        {
          "name": "Keyboard Single",
          "videoName": "V1-0065_.00000000.mp4"
        },
      ],
      AERO_Glass_Transitions: [
        {
          "name": "Up",
          "videoName": "V1-0001_A1.mp4"
        },
        {
          "name": "Down",
          "videoName": "V1-0002_A2.mp4"
        },
        {
          "name": "Right",
          "videoName": "V1-0003_A3.mp4"
        },
        {
          "name": "Upper Right",
          "videoName": "V1-0004_A4.mp4"
        },
        {
          "name": "Lower Right",
          "videoName": "V1-0005_A5.mp4"
        },
        {
          "name": "Right Diagonal Expand",
          "videoName": "V1-0006_A6.mp4"
        },
        {
          "name": "Circle Expand",
          "videoName": "V1-0007_A7.mp4"
        },
        {
          "name": "Circle Shrink",
          "videoName": "V1-0008_A8.mp4"
        },
        {
          "name": "Vertical Expand",
          "videoName": "V1-0009_A9.mp4"
        },
        {
          "name": "Vertical Shrink",
          "videoName": "V1-0010_A10.mp4"
        },
        {
          "name": "Left",
          "videoName": "V1-0011_A11.mp4"
        },
        {
          "name": "Upper Left",
          "videoName": "V1-0012_A12.mp4"
        },
        {
          "name": "Lower Left",
          "videoName": "V1-0013_A13.mp4"
        },
        {
          "name": "Left Diagonal Expand",
          "videoName": "V1-0014_A14.mp4"
        },
        {
          "name": "Rotate Up",
          "videoName": "V1-0015_A15.mp4"
        },
        {
          "name": "Rotate Down",
          "videoName": "V1-0016_A16.mp4"
        },
        {
          "name": "Rotate Right",
          "videoName": "V1-0017_A17.mp4"
        },
        {
          "name": "Rotate Left",
          "videoName": "V1-0018_A18.mp4"
        },
        {
          "name": "Horizontal Expand",
          "videoName": "V1-0019_A19.mp4"
        },
        {
          "name": "Horizontal Shrink",
          "videoName": "V1-0020_A20.mp4"
        },
        {
          "name": "Arrow Up",
          "videoName": "V1-0021_A21.mp4"
        },
        {
          "name": "Arrow Down",
          "videoName": "V1-0022_A22.mp4"
        },
      ],
      Rainbow_Transition: [
        {
          "name": "Expand",
          "videoName": "V1-0001_C1.mp4"
        },
        {
          "name": "Expand Circle",
          "videoName": "V1-0002_C2.mp4"
        },
        {
          "name": "Rainbow Stripes",
          "videoName": "V1-0003_C3.mp4"
        },
        {
          "name": "Fold",
          "videoName": "V1-0004_C4.mp4"
        },
        {
          "name": "Fold Circle",
          "videoName": "V1-0005_C5.mp4"
        },
        {
          "name": "Fold Fan",
          "videoName": "V1-0006_C6.mp4"
        },
        {
          "name": "Fold Fan Circle",
          "videoName": "V1-0007_C7.mp4"
        },
        {
          "name": "Slide Blinds",
          "videoName": "V1-0008_C8.mp4"
        },
        {
          "name": "Slide Stairs",
          "videoName": "V1-0009_C9.mp4"
        },
        {
          "name": "Cross Through",
          "videoName": "V1-0010_C10.mp4"
        },
        {
          "name": "Cross Through Angle",
          "videoName": "V1-0011_C11.mp4"
        },
      ],
      Split_Screen_Templates: [
        {
          name:"Diagonal 1x2",
          videoName: "V1-0036_对角线1x2.mp4",
          newTemplate: true
        },
        {
          name:"Bevel Cut 1x2",
          videoName: "V1-0037_斜切1x2.mp4",
          newTemplate: true
        },
        {
          name:"Vert Y",
          videoName: "V1-0038_垂直Y.mp4",
          newTemplate: true
        },
        {
          name:"Shear 3x1",
          videoName: "V1-0039_倾斜3x1.mp4",
          newTemplate: true
        },
        {
          name:"Center 2x2 Custom",
          videoName: "V1-0040_棋盘2x2自定义.mp4",
          newTemplate: true
        },
        {
          name:"Center 2x1x2 Custom",
          videoName: "V1-0041_田字2x1x2自定义.mp4",
          newTemplate: true
        },
        {
          name:"Center 1x2x2 Custom",
          videoName: "V1-0042_田字1x2x2自定义.mp4",
          newTemplate: true
        },
        {
          name:"Center 2x2x1 Custom",
          videoName: "V1-0043_田字2x2x1自定义.mp4",
          newTemplate: true
        },

        {
          name:"Dynamic 1xN",
          videoName: "V1-0004_1xn.mp4",
          newTemplate: true
        },
        {
          "name": "Cross 1x4",
          "videoName": "V1-0001_C1.mp4"
        },
        {
          "name": "Shear 1x2 Custom",
          "videoName": "V1-0002_C2.mp4"
        },
        {
          "name": "Shear 1x3",
          "videoName": "V1-0003_C3.mp4"
        },
        {
          "name": "Shear 2x2",
          "videoName": "V1-0004_C4.mp4"
        },
        {
          "name": "Shear Vx3",
          "videoName": "V1-0005_C5.mp4"
        },
        {
          "name": "Shear Vx5",
          "videoName": "V1-0006_C6.mp4"
        },
        {
          "name": "Vert 1x2 Custom",
          "videoName": "V1-0007_C7.mp4"
        },
        {
          "name": "Vert 1x3",
          "videoName": "V1-0008_C8.mp4"
        },
        {
          "name": "Vert 1x4",
          "videoName": "V1-0009_C9.mp4"
        },
        {
          "name": "Vert 1x5",
          "videoName": "V1-0010_C10.mp4"
        },
        {
          "name": "Vert 1xM",
          "videoName": "V1-0011_C11.mp4"
        },
        {
          "name": "Vert 1xT Custom",
          "videoName": "V1-0012_C12.mp4"
        },
        {
          "name": "Vert Mx1",
          "videoName": "V1-0013_C13.mp4"
        },
        {
          "name": "Vert Tx1 Custom",
          "videoName": "V1-0014_C14.mp4"
        },
        {
          "name": "Vert Yx3",
          "videoName": "V1-0015_C15.mp4"
        },
        {
          "name": "Center 2x1x2",
          "videoName": "V1-0016_C16.mp4"
        },
        {
          "name": "Horiz 1x2 Custom",
          "videoName": "V1-0017_C17.mp4"
        },
        {
          "name": "Horiz 1x2x1",
          "videoName": "V1-0018_C18.mp4"
        },
        {
          "name": "Horiz 1x3",
          "videoName": "V1-0019_C19.mp4"
        },
        {
          "name": "Horiz 1x4",
          "videoName": "V1-0020_C20.mp4"
        },
        {
          "name": "Horiz 1x5",
          "videoName": "V1-0021_C21.mp4"
        },
        {
          "name": "Horiz 1xM",
          "videoName": "V1-0022_C22.mp4"
        },
        {
          "name": "Horiz 1xT Custom",
          "videoName": "V1-0023_C23.mp4"
        },
        {
          "name": "Horiz Mx1",
          "videoName": "V1-0024_C24.mp4"
        },
        {
          "name": "Horiz Tx1 Custom",
          "videoName": "V1-0025_C25.mp4"
        },
        {
          "name": "Slide Blinds Vertical",
          "videoName": "V1-0026_C26.mp4"
        },
        {
          "name": "Slide Blinds Horizontal",
          "videoName": "V1-0027_C27.mp4"
        },
        {
          "name": "Vortex 1x5",
          "videoName": "V1-0028_C28.mp4"
        },
        {
          "name": "Cross 2x1x2",
          "videoName": "V1-0029_C29.mp4"
        },
        {
          "name": "Cross 2x2 Custom",
          "videoName": "V1-0030_C30.mp4"
        },
        {
          "name": "Cross 2x3",
          "videoName": "V1-0031_C31.mp4"
        },
        {
          "name": "Cross 3x2",
          "videoName": "V1-0032_C32.mp4"
        },
        {
          "name": "Cross 3x3",
          "videoName": "V1-0033_C33.mp4"
        },
        {
          "name": "Cross 4x2",
          "videoName": "V1-0034_C34.mp4"
        },
      ],
      Standard_Transitions_V2: [
        {
          "name": "Pixel Grid Zoom In",
          "videoName": "V1-0001_C1.mp4",
          "classify": "Pixel Grid"
        },
        {
          "name": "Pixel Grid Erase",
          "videoName": "V1-0002_C2.mp4",
          "classify": "Pixel Grid"
        },
        {
          "name": "Pixel Grid Erase Random",
          "videoName": "V1-0003_C3.mp4",
          "classify": "Pixel Grid"
        },
        {
          "name": "Pixel Grid Shrink",
          "videoName": "V1-0004_C4.mp4",
          "classify": "Pixel Grid"
        },
        {
          "name": "Pixel Grid Random",
          "videoName": "V1-0005_C5.mp4",
          "classify": "Pixel Grid"
        },
        {
          "name": "Glare 01",
          "videoName": "V1-0006_C6.mp4",
          "classify": "Glare"
        },
        {
          "name": "Glare 02",
          "videoName": "V1-0007_C7.mp4",
          "classify": "Glare"
        },
        {
          "name": "Glare 03",
          "videoName": "V1-0008_C8.mp4",
          "classify": "Glare"
        },
        {
          "name": "Glare 04",
          "videoName": "V1-0009_C9.mp4",
          "classify": "Glare"
        },
        {
          "name": "Glare 05",
          "videoName": "V1-0010_C10.mp4",
          "classify": "Glare"
        },
        {
          "name": "Glare 06",
          "videoName": "V1-0011_C11.mp4",
          "classify": "Glare"
        },
        {
          "name": "Zoom In and Zoom In",
          "videoName": "V1-0012_C12.mp4",
          "classify": "Zoom"
        },
        {
          "name": "Zoom In and Zoom Out",
          "videoName": "V1-0013_C13.mp4",
          "classify": "Zoom"
        },
        {
          "name": "Zoom Out and Zoom In",
          "videoName": "V1-0014_C14.mp4",
          "classify": "Zoom"
        },
        {
          "name": "Zoom Out and Zoom Out",
          "videoName": "V1-0015_C15.mp4",
          "classify": "Zoom"
        },
        {
          "name": "Zoom Soften",
          "videoName": "V1-0016_C16.mp4",
          "classify": "Zoom"
        },
        {
          "name": "Shape Concentric Circles",
          "videoName": "V1-0017_C17.mp4",
          "classify": "Shape"
        },
        {
          "name": "Shape Concentric Circles Corners",
          "videoName": "V1-0018_C18.mp4",
          "classify": "Shape"
        },
        {
          "name": "Shape Fluid",
          "videoName": "V1-0019_C19.mp4",
          "classify": "Shape"
        },
        {
          "name": "Shape Lines Scanlines",
          "videoName": "V1-0020_C20.mp4",
          "classify": "Shape"
        },
        {
          "name": "Shape Lines Slide",
          "videoName": "V1-0021_C21.mp4",
          "classify": "Shape"
        },
        {
          "name": "Shape Lines Blinds",
          "videoName": "V1-0022_C22.mp4",
          "classify": "Shape"
        },
        {
          "name": "Album Cards Slide Vertical",
          "videoName": "V1-0023_C23.mp4",
          "classify": "Album"
        },
        {
          "name": "Album Cards Slide Horizontal",
          "videoName": "V1-0024_C24.mp4",
          "classify": "Album"
        },
        {
          "name": "Album Text Slide Vertical",
          "videoName": "V1-0025_C25.mp4",
          "classify": "Album"
        },
        {
          "name": "Album Text Slide Horizontal",
          "videoName": "V1-0026_C26.mp4",
          "classify": "Album"
        },
        {
          "name": "Shake",
          "videoName": "V1-0027_C27.mp4",
          "classify": "Shake"
        },
        {
          "name": "Stretch Vertical",
          "videoName": "V1-0028_C28.mp4",
          "classify": "Position"
        },
        {
          "name": "Stretch Horizontal",
          "videoName": "V1-0029_C29.mp4",
          "classify": "Position"
        },
        {
          "name": "Shift",
          "videoName": "V1-0030_C30.mp4",
          "classify": "Position"
        },
        {
          "name": "Shift Gap",
          "videoName": "V1-0031_C31.mp4",
          "classify": "Position"
        },
        {
          "name": "Glitch Old TV 01",
          "videoName": "V1-0032_C32.mp4",
          "classify": "Glitch"
        },
        {
          "name": "Glitch Old TV 02",
          "videoName": "V1-0033_C33.mp4",
          "classify": "Glitch"
        },
        {
          "name": "Glitch Old TV 03",
          "videoName": "V1-0034_C34.mp4",
          "classify": "Glitch"
        },
        {
          "name": "Glitch Color Bars 01",
          "videoName": "V1-0035_C35.mp4",
          "classify": "Glitch"
        },
        {
          "name": "Glitch Color Bars 02",
          "videoName": "V1-0036_C36.mp4",
          "classify": "Glitch"
        },
        {
          "name": "Glitch Color Bars 03",
          "videoName": "V1-0037_C37.mp4",
          "classify": "Glitch"
        },
        {
          "name": "Glitch Color Bars 04",
          "videoName": "V1-0038_C38.mp4",
          "classify": "Glitch"
        },
        {
          "name": "Glitch Color Bars 05",
          "videoName": "V1-0039_C39.mp4",
          "classify": "Glitch"
        },
        {
          "name": "Text Zoom Through",
          "videoName": "V1-0040_C40.mp4",
          "classify": "Zoom"
        },
        {
          "name": "Luminance",
          "videoName": "V1-0041_C41.mp4",
          "classify": "Brightness"
        },
        {
          "name": "Soften Basic",
          "videoName": "V1-0042_C42.mp4",
          "classify": "Soften"
        },
        {
          "name": "Soften Stretch Vertical",
          "videoName": "V1-0043_C43.mp4",
          "classify": "Soften"
        },
        {
          "name": "Soften Stretch Horizontal",
          "videoName": "V1-0044_C44.mp4",
          "classify": "Soften"
        },
        {
          "name": "Soften Erase",
          "videoName": "V1-0045_C45.mp4",
          "classify": "Soften"
        },
        {
          "name": "Soften Slide",
          "videoName": "V1-0046_C46.mp4",
          "classify": "Soften"
        },
        {
          "name": "Soften Blink",
          "videoName": "V1-0047_C47.mp4",
          "classify": "Soften"
        },
        {
          "name": "Slide",
          "videoName": "V1-0048_C48.mp4",
          "classify": "Position"
        },
        {
          "name": "Slide Depth of Field",
          "videoName": "V1-0049_C49.mp4",
          "classify": "Position"
        },
        {
          "name": "Focus 01",
          "videoName": "V1-0050_C50.mp4",
          "classify": "Focus"
        },
        {
          "name": "Focus 02",
          "videoName": "V1-0051_C51.mp4",
          "classify": "Focus"
        },
        {
          "name": "Focus 03",
          "videoName": "V1-0052_C52.mp4",
          "classify": "Focus"
        },
        {
          "name": "Focus 04",
          "videoName": "V1-0053_C53.mp4",
          "classify": "Focus"
        },
        {
          "name": "Page Turn",
          "videoName": "V1-0054_C54.mp4",
          "classify": "Page Turn"
        },
        {
          "name": "Spin",
          "videoName": "V1-0055_C55.mp4",
          "classify": "Spin"
        },
        {
          "name": "Spin Zoom",
          "videoName": "V1-0056_C56.mp4",
          "classify": "Spin"
        },
        {
          "name": "Spin Depth of Field",
          "videoName": "V1-0057_C57.mp4",
          "classify": "Spin"
        },
        {
          "name": "Mask Concentric Circles 1",
          "videoName": "V1-0058_C58.mp4",
          "classify": "Mask"
        },
        {
          "name": "Mask Concentric Circles 2",
          "videoName": "V1-0059_C59.mp4",
          "classify": "Mask"
        },
        {
          "name": "Mask Rectangle Vertical",
          "videoName": "V1-0060_C60.mp4",
          "classify": "Mask"
        },
        {
          "name": "Mask Rectangle Horizontal",
          "videoName": "V1-0061_C61.mp4",
          "classify": "Mask"
        },
        {
          "name": "Flicker",
          "videoName": "V1-0062_C62.mp4",
          "classify": "Flicker"
        },
        {
          "name": "Flicker Soft Light",
          "videoName": "V1-0063_C63.mp4",
          "classify": "Flicker"
        },
        {
          "name": "Mosaic Corners",
          "videoName": "V1-0064_C64.mp4",
          "classify": "Mosaic"
        },
        {
          "name": "Mosaic Circular",
          "videoName": "V1-0065_C65.mp4",
          "classify": "Mosaic"
        },
        {
          "name": "Mosaic Slide",
          "videoName": "V1-0066_C66.mp4",
          "classify": "Mosaic"
        },
      ],
      Image_Effect_Templates: [
        {
          name: "clip art",
          videoName: "V1-0025_C25.mp4",
          newTemplate: true
        },
        {
          "name": "3D Tracking",
          "videoName": "V1-0001_C1.mp4"
        },
        {
          "name": "Portrait Stroke",
          "videoName": "V1-0002_C2.mp4"
        },
        {
          "name": "Image Stretch",
          "videoName": "V1-0003_C3.mp4"
        },
        {
          "name": "Erase Shape",
          "videoName": "V1-0004_C4.mp4"
        },
        {
          "name": "Rotate Depth of Field",
          "videoName": "V1-0005_C5.mp4"
        },
        {
          "name": "Proportional Cropping",
          "videoName": "V1-0006_C6.mp4"
        },
        {
          "name": "Slide",
          "videoName": "V1-0007_C7.mp4"
        },
        {
          "name": "Slide Lively",
          "videoName": "V1-0008_C8.mp4"
        },
        {
          "name": "Slide Path",
          "videoName": "V1-0009_C9.mp4"
        },
        {
          "name": "Magical Zoom",
          "videoName": "V1-0010_C10.mp4"
        },
        {
          "name": "Magical Outline",
          "videoName": "V1-0011_C11.mp4"
        },
        {
          "name": "Spin",
          "videoName": "V1-0012_C12.mp4"
        },
        {
          "name": "Spin Lively",
          "videoName": "V1-0013_C13.mp4"
        },
        {
          "name": "Rhythm Zoom",
          "videoName": "V1-0014_C14.mp4"
        },
        {
          "name": "Rhythm Zoom RGB",
          "videoName": "V1-0015_C15.mp4"
        },
        {
          "name": "Rhythm Zoom Spread",
          "videoName": "V1-0016_C16.mp4"
        },
        {
          "name": "Rhythm Shake",
          "videoName": "V1-0017_C17.mp4"
        },
        {
          "name": "Rhythm Colorful",
          "videoName": "V1-0018_C18.mp4"
        },
        {
          "name": "Rhythm Flicker",
          "videoName": "V1-0019_C19.mp4"
        },
        {
          "name": "Easy Masking",
          "videoName": "V1-0020_C20.mp4"
        },
        {
          "name": "Frame",
          "videoName": "V1-0021_C21.mp4"
        },
        {
          "name": "Shadow",
          "videoName": "V1-0022_C22.mp4"
        },
        {
          "name": "Shadow Stretch",
          "videoName": "V1-0023_C23.mp4"
        },
        {
          "name": "Shadow Gradient",
          "videoName": "V1-0024_C24.mp4"
        },
      ],
    },
  }),
});

export default useConstant;
