import { createPinia } from 'pinia'

import useConstant_cn from './modules/constant-cn'
import useConstant_en from './modules/constant-en'

import useApp from './modules/app'
import app from "./modules/app";

export const pinia = createPinia()



let lang = navigator.language;//获取浏览器默认语言。
const language = lang === 'zh-CN' ? 'cn' : 'en'

const storeObj = {
  constant: 'cn'===language ? useConstant_cn : useConstant_en,
  app: useApp
}

export function useStore(key) {
  return storeObj[key]()
}

export default pinia
